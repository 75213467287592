/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';

export * from './files/services';

export const getBudgetByProductionId = makeServiceCall('budgets', 'get', 'clear');

export const {
  get: getEpisodeById,
  create: createSeasonEpisodeCall,
  update: updateEpisodeCall,
} = makeEntityServiceCalls('episodes');

/**
 * @param {number} divisionId
 * @param {number} projectId
 * @returns {Array} The subprojects (Seasons, SeasonReleases, and Episodes) linked to a project.
 */
 export function getSeasonsSeasonReleasesAndEpisodesCall(divisionId, projectId) {
  return makeServiceCall('episodes', 'get-all')({ divisionId, projectId });
}

export const { get: getFilmReleaseById, create: createFilmReleaseCall } = makeEntityServiceCalls('film-releases');

export const createProjectAndFilmRelease = makeServiceCall('film-releases', 'create-project-release');

export const createBudgetVersionCall = makeServiceCall('budgets', 'create-version', 'clear');

export const createBudgetExpenseCall = makeServiceCall('budgets', 'create-expense', 'clear');

export const deleteExpenseCall = makeServiceCall('budgets', 'delete-expense', 'clear');

export const updateBudgetCall = makeServiceCall('budgets', 'update', 'clear');

export const updateBudgetExpenseCall = makeServiceCall('budgets', 'update-expense', 'clear');

export const getProductionUseInfoCall = makeServiceCall('tracks', 'get-use-info', 'clear');

/**
 * Return a list of Rights Owners of a given productionId that has at least one licensor
 */
export const getProductionRightsOwnersCall = makeServiceCall('licensors', 'get-rights-owners', 'clear');
