/* eslint-disable max-len */
import { SCENE_DRAWER_FORM_FIELD } from 'components/common/Drawers/SceneDrawer/constants';
import { RIGHTS_TERRITORIES_TERM } from 'components/Settings/Tabs/Rights/constants';
import { RIGHTS_HINT_MESSAGE_TYPE } from 'components/common/Drawers/RightPresetDrawer/constants';
import { IMPORT_FIELDS, IMPORT_DIVIDERS } from 'redux-core/import-cuesheet/constants';

const en = {
  translation: {
    global: {
      showHidden: 'SHOW HIDDEN',
      removeHidden: 'REMOVE HIDDEN',
      addAll: 'Add All',
      genericError: 'An unexpected error has occurred',
      versionError:
        'Information on this page has changed. Your current edits will be lost. Tip: copy any updates you need before refreshing the page',
      refreshInformation: 'Refresh Information',
      lastUpdatedByUserName: 'Last updated by {{userName}} on {{date}}',
      lastUpdatedBy: 'Last updated by {{userName}}',
      lastUpdatedAt: 'Last updated on {{date}}',
      ok: 'OK',
      hide: 'HIDE',
      show: 'SHOW',
      clear: 'CLEAR',
      clearFilters: 'Clear Filters',
      fileUploadError: 'Error uploading file',
      noResults: 'No results',
      startTyping: 'Start typing to search',
      seeAll: 'See All',
      addItem: 'Add Item',
      apply: 'Apply',
      cancel: 'Cancel',
      remove: 'Remove',
      search: 'Search',
      searchPlaceholder: 'Search...',
      confirmDialog: {
        cancel: 'Are you sure you want to cancel?',
        message: 'Are you sure you want to continue?',
        no: 'No',
        yes: 'Yes',
        delete: 'Are you sure you want to delete?',
      },
      select: 'Select',
      format: {
        date: 'MM/dd/yyyy',
        placeholderCurrency: '0.00',
        timestamp: 'MM/DD/YYYY HH:mm:ss a',
      },
      navbar: {
        help: 'Help',
        licensors: 'Licensors',
        licensing: 'Licensing',
        home: 'Projects',
        qwire: 'Qwire',
        reports: 'Reports',
        newReports: 'New Reports',
        adminReports: 'Admin Reports',
        settings: 'Settings',
        tasks: 'Tasks',
        cueSheets: 'Cue Sheets',
        tracks: 'Tracks',
      },
      forms: {
        add: 'Add',
        addAndContinue: 'Add & Continue',
        allOption: 'All',
        attach: 'Attach',
        noneOption: 'None',
        apply: 'Apply',
        continue: 'Continue',
        close: 'Close',
        cancel: 'Cancel',
        choose: 'Choose',
        change: 'CHANGE',
        chooseOne: 'Choose One',
        select: 'Select',
        country: 'Country',
        create: 'CREATE',
        createNew: 'Create New',
        delete: 'Delete',
        edit: 'Edit',
        from: 'From',
        download: 'Download',
        back: 'Back',
        next: 'Next',
        checkbox: {
          checked: 'Yes',
          unchecked: 'No',
        },
        ariaLabels: {
          password: 'Toggle password visibility',
        },
        notification: {
          save: 'Your changes have been saved',
        },
        save: 'Save',
        done: 'Done',
        saveAndClose: 'Save & Close',
        saveAndAddNew: 'Save & Add New',
        searchByName: 'Search by name',
        state: 'State/Prov',
        status: {
          active: 'Active',
          inactive: 'Inactive',
        },
        details: 'Details',
        timeHoursPlaceholder: 'hh:mm:ss',
        timeMinutesPlaceholder: 'mm:ss',
        timeHHMMplaceholder: 'hh:mm',
        days: 'Days',
        months: 'Months',
        update: 'Update',
        unsavedChanges: 'You have unsaved changes. Are you sure you want to continue?',
        uploadPicture: 'Upload Picture',
        removePicture: 'Remove Picture',
        files: {
          image: 'Please upload an image.',
        },
        placeholder: {
          search: 'Start Typing to search',
        },
        validations: {
          min: 'Please enter at least {{min}} characters',
          max: 'Please enter less than {{max}} characters',
          lower: "Value can't be lower than {{min}}",
          higher: "Value can't be higher than {{max}}",
          required: 'Please complete this field',
          duplicated: 'The value is duplicated',
          requiredOneOfNameFields: 'Please complete one of those name fields',
          types: {
            date: 'Please enter a valid date',
            dateStartIsBefore: 'Date is before end date',
            dateIsBefore: 'Date is before minimum permitted value',
            dateIsAfter: 'Date is after maximum permitted value',
            number: 'Please enter a number',
            string: 'Please enter text',
            email: 'Please enter a valid email',
            emails: 'Please check that all the emails are valid',
            time: 'Please enter a valid time',
            timeMax24: 'Please enter a time between 00:00:00 and 23:59:59',
          },
          /* @TODO make this rule flexible to accept value as 0 */
          moreThan: 'Value must be greater than 0',
          password: {
            atLeastLowercase: '{{contain}} at least one lowercase char',
            atLeastNumber: '{{contain}} at least one number',
            atLeastSpecial: '{{contain}} at least one special char (@,!,#, etc)',
            atLeastUppercase: '{{contain}} at least one uppercase char',
            mustMatch: 'Passwords must match',
            shouldContain: 'Password should contain',
          },
          unique: 'Value must be unique',
          sender: 'Sender ID can not be more than 9 characters',
        },
        rightsPreset: 'Rights Preset is a required field.',
        assign: 'Assign',
        sync: 'Sync',
        skip: 'Skip',
      },
      yes: 'Yes',
      no: 'No',
      accept: 'Accept',
      section: 'Section',
      instructions: {
        hide: 'Hide instructions',
        show: 'Show instructions',
      },
      actions: {
        edit: 'Edit',
        delete: 'Delete',
        download: 'Download',
        duplicate: 'Duplicate',
        removeButton: 'Remove from list',
        editOption: 'Edit selected option',
      },
      prompt: {
        cancel: 'Are you sure you want to cancel?',
        delete: 'Are you sure you want to delete?',
      },
      paginator: {
        intervalIndicator: '{{interval}} of {{total}}',
      },
      tabs: {
        ariaLabel: 'Tabs',
      },
      trackStatuses: {
        CHECK_REQUEST_SENT: 'Check Request Sent',
        CLEARED: 'Cleared',
        CONF_SENT: 'Confirmation Sent',
        DENIED: 'Denied',
        EXPIRED: 'Expired',
        LICENSE_REQUEST_SENT: 'License Request Sent',
        LICENSE_SENT: 'License Sent',
        NEW: 'New',
        PAID: 'Paid',
        QUOTE_REQ_SENT: 'Quote Request Sent',
        REQUOTE: 'Requote',
        STUDIO_OWNED: 'Studio Owned',
      },
      cueSheetSubmissionStatus: {
        NOT_SENT: 'Not Sent',
        EMAILED: 'Emailed',
        SENT: 'Sent',
        SENT_REVISED: 'Sent Revised',
        REJECTED: 'Rejected',
        ACCEPTED: 'Accepted',
        WARNING: 'Warning',
      },
      trackTypes: {
        SONG: 'Song',
        SCORE: 'Score',
        LIBRARY: 'Library',
        SOUND_EFFECT: 'SFX',
        SONG_REVISED: 'Song Revised',
      },
      trackUseTypes: {
        BI: 'BI',
        BV: 'BV',
        'BI/BV': 'BI/BV',
        VI: 'VI',
        VV: 'VV',
        MT: 'MT',
        ET: 'ET',
        LOGO: 'Logo',
        OT: 'OT',
      },
      descriptors: {
        L: 'L - Language',
        S: 'S - Sex',
        V: 'V - Violence',
        FV: 'FV - Fictional Violence',
        D: 'D - Sexual or Suggestive Dialog',
      },
      season: 'Season {{number}} ',
      fileFormats: {
        PDF: 'PDF',
        CSV: 'CSV',
        XML: 'XML',
        XLS: 'XLS',
        XLSX: 'XLSX',
        RapidCue: 'RapidCue',
        FormattedXLSX: 'Formatted XLSX',
        RapidCueXML: 'RapidCue XML',
      },
      fileTypes: {
        // Track Types
        QUOTE: 'Quote',
        QUOTE_REQUEST_SENT: 'Quote Request',
        CONFIRMATION: 'Confirmation',
        APPROVAL: 'Approval',
        LICENSE: 'License',
        LICENSE_REQUEST_SENT: 'License Request',
        CHECK_REQUEST_SENT: 'Check Request',
        ADDITIONAL_CORRESPONDENCE: 'Additional Correspondence',
        END_TITLE_APPROVALS: 'End Title Approvals',
        SHEET_MUSIC: 'Sheet Music',
        LYRICS: 'Lyrics',
        AUDIOFILE: 'Audio File',
        QUOTE_RENEWAL: 'Quote Renewal',
        CHECK_REQUEST: 'Check Request',
        PAYMENT: 'Payment',
        LICENSE_REQUEST: 'License Request',
        QUOTE_REQUEST: 'Quote Request',
        // Production Types
        CUE_SHEET: 'Cue Sheet',
        PRODUCTION_INFO: 'Production Info',
        CALENDAR: 'Calendar',
        SCRIPT: 'Script',
        OTHER: 'Other',
      },
      payeeTypes: {
        INDIVIDUAL: 'Individual',
        COMPANY: 'Company',
      },
      roles: {
        divisionAdmin: 'Division Admin',
        owner: 'Owner',
        editor: 'Editor',
        reviewer: 'Reviewer',
        none: 'None',
      },
    },
    styleguide: {
      repAvatar: {
        initials: 'rep initials',
        picture: 'rep picture',
      },
    },
    charts: {
      budget: {
        budget: 'BUDGET',
        committed: 'Committed',
        potential: 'Potential',
        uncommited: 'Uncommitted',
        over: 'over',
      },
      tracks: {
        types: {
          effects: 'Sfx:',
          libraries: 'Library:',
          scores: 'Score:',
          songs: 'Song:',
        },
        labels: {
          new: '<0>New</0> (<1>{{count}}</1>)',
          inProgress: '<0>In Prog</0> (<1>{{count}}</1>)',
          cleared: '<0>Cleared</0> (<1>{{count}}</1>)',
          confirmed: '<0>Confirmed</0> (<1>{{count}}</1>)',
          licensed: '<0>Licensing</0> (<1>{{count}}</1>)',
          other: '<0>Other</0> (<1>{{count}}</1>)',
          licensing: '<0>Licensing</0> (<1>{{count}}</1>)',
          licenseSent: '<0>Licensed</0> (<1>{{count}}</1>)',
          checkSent: '<0>Check Sent</0> (<1>{{count}}</1>)',
          paid: '<0>Paid</0> (<1>{{count}}</1>)',
          licenseRequestSent: '<0>License Request Sent</0> (<1>{{count}}</1>)',
          progress: 'PROGRESS',
          total: '{{count}} Track Total',
          total_plural: '{{count}} Tracks Total',
          totalUsed: '{{count}} Track Used',
          totalUsed_plural: '{{count}} Tracks Used',
        },
      },
    },
    topbar: {
      bookmarks: 'Bookmarks',
      bookmarkDeleted: 'Bookmark deleted successfully',
      noTracksBookmarked: 'No tracks bookmarked',
      search: 'Search...',
      recent: 'Recent',
      results: 'Search Results',
      userMenu: {
        profile: 'Profile',
        help: {
          title: 'Help',
          emailUs: 'Email to:',
          callUs: 'Call Us: {{phone}}',
        },
        logout: 'Logout',
      },
    },
    grid: {
      filters: {
        disclosureButton: 'Filter',
        disclosureButton_plural: 'Filters',
        addFilters: 'Add Filter',
      },
    },
    drawers: {
      advancedFilters: {
        drawerTitle: 'Advanced Filters',
        cuesTitle: 'Cues',
        assetsTitle: 'Assets',
        sceneFilters: {
          cueContainerIds: 'Scene',
        },
        cuesFilters: {
          all: 'All',
          using: 'Using',
          not_using: 'Not Using',
          trackStatuses: 'Status',
          trackTypes: 'Track Types',
          trackUseTypes: 'Track Use Types',
          trackTags: 'Track Tags',
          clearanceRepIds: 'Clearance Rep',
          libraryIds: 'Library',
        },
        assetsFilters: {
          assets: 'Name',
          assetStatuses: 'Status',
          assetTypes: 'Type',
          vendors: 'Creative Vendor',
          completedStatuses: 'Complete Status',
        },
      },
      cueSheetDetails: {
        drawerTitle: 'Cue Details',
        title: 'Title',
        artists: 'Artist',
        notes: 'Notes',
        iswc: 'ISWC',
        isrc: 'ISRC',
        cutNumber: 'Library Cut#',
        cdNumber: 'Library CD#',
        explicit: 'Explicit',
        theme: 'Theme',
        usageDetail: 'Detailed Usage',
        masterLicensor: 'Master Licensor',
        syncLicensor: 'Sync Licensor',
        share: 'Share',
      },
      cueSheetSubmissionHistory: {
        drawerTitle: 'Cue Sheet Submission History',
        newSubmission: 'New Submission',
        disabledSubmitTooltip: 'Only the Current Version may be submitted',
        hint: 'Hint:',
        hintText:
          'Progress of cue sheets that are already in the submission process are below, and you can view the submission progress by expanding each row. The status can be updated as needed by clicking on the update button for the corresponding PRO.',
        hintSubtext: 'To submit an additional cue sheet, use the “NEW SUBMISSION” button in the top right corner.',
        goToSubmit: 'Are you sure you want to go to Submit Cue Sheet? Your unsaved changes will be lost.',
        table: {
          version: 'Version',
          dateSubmitted: 'Date Submitted',
          fileType: 'File Type',
          method: 'Method',
          methodManually: 'Manually',
          methodElectronically: 'Electronically',
          recipient: 'Recipient',
          submissionStatus: 'Submission Status',
          user: 'User',
          actions: {
            updateSubmission: 'Update Submission',
            viewResponse: 'View Response',
            downloadResponse: 'Download Response',
            downloadXML: 'Download XML',
          },
        },
        successMessage: 'Your changes has been saved',
        errorsAndWarnings: {
          errors: 'Errors',
          warnings: 'Warnings',
          save: 'Save',
          close: 'Close',
        },
      },
      cueSheetVersion: {
        newVersion: 'New Version',
        editVersion: 'Edit Version',
        deleteVersion: 'Delete Version',
        duplicate: 'Duplicate',
        defaultVersion: 'Version {{date}}',
        defaultDuplicate: '{{title}} - COPY',
        duplicateVersion: 'Duplicate Version',
        makeCurrent: 'Make Current Version',
        hint: 'This will create a new current version of the cue sheet. You can always change and edit the version details at anytime',
        name: 'Version Name',
        notes: 'Additional Notes',
        notesPlaceholder: 'Write something...',
        notifications: {
          create: 'Cue Sheet Version Created Successfully',
          edit: 'Cue Sheet Version Edited Successfully',
          delete: 'Cue Sheet Version Deleted Successfully',
          deletePrompt: 'Are you sure you want to delete {{title}}?',
          duplicate: 'Cue Sheet Version Duplicated Successfully',
        },
      },
      importCueSheet: {
        drawerTitle: 'Import File',
        hintTitle: 'Hint:',
        fileHint:
          'Start the cue sheet import process by dragging your file into the space below. If you want to use the file browser on your computer, simply click in the area below.',
        templatesHint: 'Choose to continue with an import template or to import the file using our file mapping tool',
        yourFile: 'Your File',
        matchingMethod: 'Choose a Matching Method',
        removeFile: 'Remove',
        noTemplate: 'No Template',
        notifications: {
          addFav: 'Template added to favorites',
          remove: 'Are you sure you want to delete {{name}}?',
          removed: 'Template removed successfully',
          removeFav: 'Template removed from favorites',
          cancel: 'Are you sure you want to exit the import workflow? All of your changes will be lost',
          pleaseWait: 'Please wait while we create your cue sheet',
        },
        edlMaxLinesExceeded:
          'This file exceeds 500 cues. Please break up your Cue Sheet into separate files and try again.',
      },
      cueAdvancedSearch: {
        drawerTitle: 'Advanced Search',
        warningMessage: "You're about to update your cue using qwireTracks information. Do you want to proceed?",
      },
      changeCueDetails: {
        drawerTitle: 'Change Cue Details',
        donotChange: "DON'T CHANGE",
        successMessage: 'Update Saved',
        trackType: 'Track Type',
        usage: 'Usage',
      },
      lettersExtraAttachmentsDrawer: {
        file: {
          name: 'File Name',
          type: 'Type',
        },
        title: 'Attach Files',
      },
      search: {
        cancelPrompt: 'Are you sure you want to cancel?',
        drawerTitle: 'Search',
        searchHint: 'Start typing to see results',
        searchPlaceholder: 'Search...',
      },
      searchProductions: {
        drawerTitle: 'Select Production',
        features: 'Release',
        marketing: 'Campaign',
        noResults: 'No Results',
        project: 'Project',
        release: 'Release',
        season: 'Season',
        television: 'Episode',
        productionType: 'Production Type',
        showInactived: 'Show Inactive Productions',
      },
      submitCueSheet: {
        drawerTitle: 'Cue Sheet Submission',
        submit: 'Submit',
        hint: 'Hint:',
        hintText:
          'The Cue Sheet Submission process will help you keep track of PROs that you have submitted to, the type of file you have submitted and the submission status.',
        datestamp: 'Submission Date',
        fileType: 'Choose a File Type',
        hintSubtext: 'Follow the steps below to get started.',
        pro: 'Choose Recipients',
        submissionStatus: 'Submission Status',
        submissionStatusPlaceholder: 'Set Submission Status',
        successMessage: 'Cue Sheet Submission created successfully',
        recipient: 'Partner',
        rapidCueFailedValidationMessage:
          'You have missing data. You cannot submit to RapidCue until all data and/or cues are complete. For help contact support@qwire.com.',
        rapidCueFailedValidationDivision: 'Missing from Division:',
        rapidCueFailedValidationProduction: 'Missing from Production:',
        rapidCueFailedValidationWithoutUsage: 'Without usage:',
        rapidCueFailedValidationWithoutDuration: 'Without duration:',
        rapidCueFailedValidationWithoutRole: 'Without role:',
        rapidCueFailedValidationWithInvalidShares: 'With invalid shares:',
        rapidCueFailedValidationWithoutPublisherOrWriter: 'Without publisher or writer:',
        rapidCueFileTooBig: 'This cue sheet is too large to send to RapidCue. You must email a PDF to RapidCue.',
        rapidCueSubmitted: 'Cue sheet submitted to RapidCue.',
        rapidCueSubmissionPending: 'Cue sheet submission is pending.',
        rapidCueSubmissionError: 'Error submitting to RapidCue. For help contact support@qwire.com',
      },
      importSaveAsTemplate: {
        cancel: 'Cancel',
        drawerTitle: 'Save As Template',
        name: 'Name',
        namePlaceholder: 'Enter Name',
        noResults: 'There are no existing templates for this division.',
        or: 'OR',
        replaceExisting: 'Replace Existing Template',
        saveAndImport: 'Save & Import',
        notifications: {
          added_plural: '{{count}} Cues Added',
          added: '{{count}} Cue Added',
          download: '<0>Download</0> <1>to view file of failed cues</1>',
          failed_plural: '{{count}} Cues Failed',
          failed: '{{count}} Cue Failed',
          importComplete: 'Import Complete',
          ok: 'OK',
          replaceTemplate: 'Replace the Template {{name}}?',
        },
      },
      unassignedBin: {
        assetRights: 'Asset Rights',
        edit: 'Edit Asset - Unassigned Bin',
      },
      assets: {
        titles: {
          edit: 'Edit Asset',
          new: 'New Asset',
          finishes: 'Finishes',
        },
        labels: {
          creativeVendors: 'Creative Vendors',
          date: 'Date',
          dateUsed: 'Date Used',
          finisher: 'Finisher',
          finishers: 'Finishers',
          finishes: 'Finishes',
          finishingDate: 'Finishing Date',
          finishingVendors: 'Finishing Vendors',
          isciNumber: 'ISCI#',
          notes: 'Notes',
          requiredRights: 'Asset Rights',
          title: 'Asset Title',
          type: 'Asset Type',
          versionName: 'Version Name',
          version: 'Ver #',
          versionTRT: 'TRT',
        },
        form: {
          noResults: 'No Results',
          confirmRightsUpdateForAsset: 'Do you want to apply these new rights to ALL tracks in this asset?',
        },
        notifications: {
          create: {
            success: 'Asset Created Successfully',
            failed: 'There was an error while creating the Asset, please try again',
          },
          update: {
            success: 'Asset Updated Successfully',
            failed: 'There was an error while updating the Asset, please try again',
          },
        },
      },
      watchingAssets: {
        approvedFees: 'Total Approved Fees',
        assetType: 'Asset Type',
        creativeVendor: 'Creative Vendor',
        pending: '({{count}} Pending)',
        status: 'Status',
        total_plural: '{{count}} Assets',
        total: '{{count}} Asset',
        version: 'Version',
        cues: 'Cues',
        incomplete: 'Incomplete',
        totalCues: 'Total Cues',
        incompleteCues: 'Incomplete Cues',
      },
      qtracksSync: {
        header: {
          helpfulTip: 'Helpful Tip:',
          hint: 'Select the new qwireTracks information you would like to apply to to the track in your project. You can choose all, some or none of the changes to accept. You can also choose to ignore qwireTracks updates and changes for the track and you will no longer be notified.',
          ignoreFuture: 'Ignore future qwireTracks update for this track, in this production',
          title:
            'The track comparison tool highlights significant differences between the track information in your project and Qwire’s latest information in qwireTracks.',
          trackComparision: 'Track Comparison',
          trackVersion: 'qwireTracks Version',
        },
        diff: {
          selectAll: 'Select All',
          changes: '{{count}} Update',
          changes_plural: '{{count}} Updates',
          qTracksDetails: 'qwireTracks Details',
          productionDetails: 'Production Details',
          trackDetails: {
            group: 'Track Details',
            title: 'Title',
            artists: 'Artists',
            copyright: 'Copyright Details',
            albums: 'Albums',
            writers: 'Writers',
            arrangers: 'Arrangers',
            duration: 'Duration',
            year: 'Year',
            isrc: 'ISRC',
            trackType: 'Track Type',
            library: 'Library',
            containsSamples: 'Contains Samples',
            oneStop: 'One Stop',
            unionDetails: {
              group: 'Union Details',
              nonUnion: 'Non Union',
            },
          },
          master: {
            group: 'Master Licensor ({{position}})',
            name: 'Master Licensor',
            share: 'Share Percentage',
            territory: 'Territory',
            address1: 'Address Line 1',
            address2: 'Address Line 2',
            city: 'City',
            state: 'State/Prov',
            zip: 'Zip/Postcode',
            country: 'Country',
            recordLabel: {
              group: 'Record Label {{position}}',
              name: 'Sub-Label Name',
              id: 'Label ID#',
            },
          },
          sync: {
            group: 'Sync Licensor ({{position}})',
            name: 'Sync Licensor',
            share: 'Share Percentage',
            territory: 'Territory',
            address1: 'Address Line 1',
            address2: 'Address Line 2',
            city: 'City',
            state: 'State/Prov',
            zip: 'Zip/Postcode',
            country: 'Country',
            affiliation: 'Affiliation',
            ipi: 'IPI #',
            cueSheetPublisher: 'Cue Sheet Publisher {{position}}',
          },
          other: {
            group: 'Other Licensor ({{position}})',
            name: 'Other Licensor',
            share: 'Share Percentage',
            licensorType: 'Licensor Type',
            function: 'Function',
            territory: 'Territory',
            isParentCompany: 'Is Parent Company',
            ownerSince: 'Owner Since',
            url: 'URL',
            address1: 'Address Line 1',
            address2: 'Address Line 2',
            city: 'City',
            state: 'State/Prov',
            zip: 'Zip/Postcode',
            country: 'Country',
            ipi: 'IPI #',
          },
        },
        slider: {
          changes: '{{current}} of {{total}} Changes',
        },
      },
      productionSetup: {
        drawers: {
          newProject: 'New Project',
        },
        budget: {
          budget: 'Budget',
          date: 'Date',
          drawerTitleBudget: 'Budget',
          drawerTitleBudgetHistory: 'Budget History',
          drawerHeaderInitialNote:
            'This production does not have a budget yet. Click the Update button to add the initial budget. You can change it at any time.',
          reasonForChange: 'Reason for Change',
          reasonForChangeDefault: 'Initial Budget',
          reasonForChangePlaceholder: 'budget adjusted to accommodate track changes',
          user: 'User',
        },
        project: {
          abbreviation: 'Abbreviation',
          drawerTitle: 'New Project',
          drawerEditTitle: 'Edit Project',
          details: 'Project Details',
          name: 'Project Title',
          notes: 'Notes',
          notification: {
            edit: 'Project {{name}} has been updated',
          },
          projectSynopsis: 'Project Synopsis',
          status: 'Status',
          items: {
            akaTitles: 'AKA Titles',
            fkaTitles: 'FKA Titles',
            seriesAbbreviation: 'Series Abbreviation',
          },
          validations: {
            duplicateName: 'Project Title already exists',
          },
        },
        fkaTitles: {
          drawerTitle: 'FKA Titles',
          delete: 'Are you sure you want to delete the FKA {{name}}?',
          placeholderEnterTitle: 'Enter Title',
        },
        akaTitles: {
          delete: 'Are you sure you want to delete the AKA {{title}}?',
          drawerTitle: 'AKA Titles',
          drawerEditTitle: 'Edit AKA Titles',
          placeholderCountry: 'Country',
          placeholderTitle: 'Title',
        },
        productionCompanies: {
          addTitle: 'Add Production Company',
          delete: 'Are you sure you want to delete {{name}}?',
          email1: 'Email 1',
          email2: 'Email 2',
          mainTitle: 'Production Companies',
          name: 'Name',
          namePlaceholder: 'Search for Companies',
          notes: 'Notes',
          phone1: 'Phone Number 1',
          phone2: 'Phone Number 2',
          address1: 'Address Line 1',
          address2: 'Address Line 2',
          city: 'City',
          state: 'State',
          zip: 'Zip/Postcode',
          country: 'Country',
        },
        blanketAgreements: {
          delete: 'Are you sure you want to delete {{name}}?',
          drawerTitle: 'Blanket Agreements',
        },
        season: {
          additionalCurrencies: 'Additional Currencies',
          animated: 'Animated',
          archivalOnly: 'Archival Only',
          defaultRights: 'Default Rights',
          currency: 'Currency',
          details: 'TV Season Details',
          ratingDescriptor: 'Rating Descriptor',
          internalSeasonProdId: 'Internal Season Production #',
          descriptorsPlaceholder: 'Select a descriptor',
          drawerTitle: 'New Season',
          drawerEditTitle: 'Edit Season',
          items: {
            blanketAgreements: 'Blanket Agreements',
            networkBroadcaster: 'Network/Broadcaster',
            distributors: 'Distributors',
            actors: 'Actors',
            directors: 'Directors',
            editors: 'Editors',
            producers: 'Producers',
            productionCompanies: 'Production Companies',
            keyPersonnel: 'Key Personnel',
          },
          name: 'Season Title',
          notification: {
            create: 'Season #{{name}} has been created',
            edit: 'Season #{{name}} has been updated',
          },
          validations: {
            duplicateNumber: 'Season Number already used',
          },
          personnel: 'Personnel',
          version: 'Version',
          category: 'Category',
          language: 'Language',
          territory: 'Territory',
          internalSeasonProductionNumber: 'Internal Season Production #',
          eidr: 'EIDR #',
          isan: 'ISAN #',
          defaultAdditionalInformation: 'Default Additional Information',
          defaultProductionInformation: 'Default Production Information',
          defaultPersonnel: 'Default Personnel',
          productionCurrency: 'Production Currency',
          duration: 'Duration',
          productionYear: 'Production Year',
          description: 'Season Description',
          preparedBy: {
            title: 'Default Cue Sheet Prepared By',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            phone: 'Phone',
          },
          defaultRating: 'Default Rating',
          rating: 'Rating',
          ratingsPlaceholder: 'Select a rating',
          saveAndAddRelease: 'Save & Add Release',
          seasonNumber: 'Season Number',
        },
        release: {
          drawerTitle: 'New Release',
          drawerEditTitle: 'Edit Release',
          eidr: 'EIDR #',
          isan: 'ISAN #',
          items: {
            networkBroadcaster: 'Network/Broadcaster',
            distributors: 'Distributors',
            productionCompanies: 'Production Companies',
            keyPersonnel: 'Key Personnel',
            directors: 'Directors',
            producers: 'Producers',
            editors: 'Editors',
            actors: 'Actors',
          },
          language: 'Language',
          notes: 'Notes',
          description: 'Description',
          notification: {
            create: 'Release {{name}} has been created',
            edit: 'Release {{name}} has been updated',
          },
          releaseDetails: 'Release Details',
          duration: 'Duration',
          releaseTitle: 'Release Title',
          releaseNumber: 'Internal Release #',
          animated: 'Animated',
          defaultRating: 'Default Rating',
          rating: 'Rating',
          productionYear: 'Production Year',
          ratingDescriptor: 'Rating Descriptor',
          currency: 'Currency',
          defaultRights: 'Default Rights',
          defaultProductionInformation: 'Default Production Information',
          defaultPersonnel: 'Default Personnel',
          requiredRights: 'Production Rights',
          episodeBudget: 'Episode Budget',
          defaultAdditionalInformation: 'Default Additional Information',
          version: 'Version',
          category: 'Category',
          preparedBy: {
            title: 'Default Cue Sheet Prepared by',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            country: 'Country',
            phone: 'Phone',
          },
          releasePlaceholder: 'Worldwide Release',
          saveAndAddEpisode: 'Save & Add Episode',
          territory: 'Territory',
        },
        currency: {
          drawerTitle: 'Currencies',
          drawerEditTitle: 'Edit Currencies',
          addCurrency: 'Add Currency',
          table: {
            header: {
              currency: 'Currency',
              exchangeRate: 'Exchange Rate',
            },
            errors: {
              existant: 'Currency already exists',
            },
          },
        },
        scenes: {
          drawerTitle: 'Add Scene',
          drawerEditTitle: 'Edit Scene',
          form: {
            [SCENE_DRAWER_FORM_FIELD.POSITION]: {
              label: 'Location in Project',
              placeholder: "There're no scenes available to choose",
              divider: 'Add After Scene',
            },
            [SCENE_DRAWER_FORM_FIELD.NUMBER]: {
              label: 'Scene #',
              placeholder: 'Scene number',
            },
            [SCENE_DRAWER_FORM_FIELD.LENGTH]: {
              label: 'Scene Length',
              placeholder: 'MM:SS',
            },
            [SCENE_DRAWER_FORM_FIELD.TITLE]: {
              label: 'Scene Title',
              placeholder: 'Scene title',
            },
            [SCENE_DRAWER_FORM_FIELD.DESCRIPTION]: {
              label: 'Description',
              placeholder: 'Write a scene description',
            },
            [SCENE_DRAWER_FORM_FIELD.NOTES]: {
              label: 'Notes',
              placeholder: 'Scene notes',
            },
          },
          notification: {
            save: 'Scene {{name}} was saved',
            edit: 'Scene {{name}} was updated',
            prompt: 'There are unsaved changes. Are you sure you want to Cancel?',
          },
        },
        personnel: {
          delete: 'Are you sure you want to delete {{name}}?',
          drawerTitle: 'Key Personnel',
          drawerEditTitle: 'Edit Key Personnel',
        },
        position: {
          drawerTitle: 'Position',
          drawerEditTitle: 'Edit Position',
        },
        addKeyPersonnel: {
          drawerTitle: 'Add Key Personnel',
          drawerEditTitle: 'Edit Key Personnel',
          prefix: 'Prefix',
          firstName: 'First Name',
          lastName: 'Last Name',
          phoneNumber: 'Phone number',
          email: 'Email',
          position: 'Position',
          notes: 'Notes',
        },
        editors: {
          delete: 'Are you sure you want to delete {{name}}?',
          drawerTitle: 'Editors',
        },
        distributors: {
          delete: 'Are you sure you want to delete {{name}}?',
          drawerTitle: 'Distributors',
        },
        networkBroadcaster: {
          delete: 'Are you sure you want to delete {{name}}?',
          drawerTitle: 'Network/Broadcaster',
        },
        producers: {
          delete: 'Are you sure you want to delete {{name}}?',
          drawerTitle: 'Producers',
        },
        directors: {
          delete: 'Are you sure you want to delete {{name}}?',
          drawerTitle: 'Directors',
          role: 'Role',
          director: 'Director',
          actorDirector: 'Actor/Director',
        },
        actors: {
          delete: 'Are you sure you want to delete {{name}}?',
          drawerTitle: 'Actors',
          role: 'Role',
          actor: 'Actor',
          actorDirector: 'Actor/Director',
        },
        defaultProducers: {
          delete: 'Are you sure you want to delete {{name}}?',
          drawerTitle: 'Default Producers',
        },
        defaultDirectors: {
          delete: 'Are you sure you want to delete {{name}}?',
          drawerTitle: 'Default Directors',
        },
        defaultActors: {
          delete: 'Are you sure you want to delete {{name}}?',
          drawerTitle: 'Default Actors',
        },
        defaultDistributors: {
          delete: 'Are you sure you want to delete {{name}}?',
          drawerTitle: 'Default Distributors',
        },
        defaultNetworks: {
          delete: 'Are you sure you want to delete {{name}}?',
          drawerTitle: 'Default Networks',
        },
        tracks: {
          add: 'ADD',
          added: 'ADDED',
          drawerTitle: 'Add Track',
          createNew: 'Create New Track',
          addTrackTo: 'Add track(s) to:',
          reset: 'Reset',
          filter: {
            artist: 'Artist',
            artistPlaceholder: 'Search for Artist',
            library: 'Library',
            search: 'Search',
            searchPlaceholder: 'Search by Title or Track ID',
            title: 'Title',
            titlePlaceholder: 'Search for Title',
            trackTitlePlaceholder: 'Enter Track Title',
            trackType: 'Track Type',
            tracks: 'Track',
            score: 'Score',
            libraryTracks: 'Library Track',
            soundEffects: 'Sound Effect',
            contentMode: 'Content Mode',
            createNew: 'Create New',
            backToSearch: 'Back to Search',
          },
          searchPlaceholder: {
            heading: 'Find and add your tracks',
            bodyPrimary:
              'Search for tracks and if you can’t find what you’re looking for, click Create New to add a new track.',
          },
          createPlaceholder: {
            heading: 'Trying to create a new track?',
            bodyPrimary:
              'Enter your track Title to create a new track, Artist is optional. Once you’ve added the title, you can add the Rights Holders or add them later in the Track details screen.',
            bodySecondary: 'Click Back to Search to find existing tracks.',
          },
          cuePopover: {
            usageDetails: 'Usage Details',
            dontShowAgain: "Don't show again",
            duration: 'Duration',
            next: 'Next',
            setDuration: 'Set Duration & Usage',
            use: 'Use',
            fullUse: 'Full Use Duration',
          },
          hint: {
            title: 'Find and add your track',
            subtitle:
              'Search qwireTracks using the search box, and choose where the track should be added. You can add as many tracks as you would like.',
            legend: 'If you can’t find your track, you can add it with the “Create New Track” button.',
          },
          headers: {
            add: 'Add',
            artist: 'Artist',
            completeness: 'Completeness',
            duplicate: 'Duplicate',
            lastUpdated: 'Last Updated',
            title: 'Title',
            trackId: 'Track ID',
            type: 'Type',
            writers: 'Writer(s)',
            dataCompletenessHigh: 'Data Completeness: High',
            dataCompletenessLow: 'Data Completeness: Low',
            dataCompletenessMedium: 'Data Completeness: Medium',
            verified: 'VERIFIED',
            totalCountSong: '{{totalCount}} commercial track(s) found',
            totalCountLibrary: '{{totalCount}} library track(s) found',
            noResults: 'Your search found no matches',
            preCleared: 'PRE-CLEARED',
          },
          details: {
            inProduction: 'In Production',
            version: 'Version',
            newPerformance: 'Create New Performance',
            trackDetails: 'TRACK DETAILS',
            album: 'Album',
            artist: 'Artist',
            writer: 'Writer',
            duration: 'Duration',
            explicit: 'Explicit',
            label: 'Label',
            master: 'Master',
            pro: 'PRO',
            sync: 'Sync',
            role: 'Role',
            share: 'Share',
            title: 'Title',
            verifiedByPublisher: 'VERIFIED',
            library: 'Library Name',
            subLibraryName: 'Sub-Library Name',
            libraryTrackId: 'Library Track ID',
            albumDescription: 'Album Description',
            catalogNumber: 'Catalog Number',
            subLibraryId: 'Sub-Library ID',
            trackDescription: 'Track Description',
            albumId: 'Album ID',
            subAlbumId: 'Sub-Album ID',
            trackNumber: 'Track Number',
            trackIndex: 'Track Index',
            iswc: 'ISWC',
            isrc: 'ISRC',
            masterLicensor: 'Master Licensor',
            syncLicensor: 'Sync Licensor',
            publisher: 'Publisher',
          },
          createTrack: {
            addTrack: 'Add Track',
            duplicateTrack: 'Duplicate Track',
            helpfulDetail: "You can always edit track details in the track's Clearance Detail.",
            helpfulHint: 'Helpful hint:',
            newPerformance: 'New Performance',
            newTrack: 'Create New Track',
            form: {
              songType: 'Commercial',
              trackDetails: 'Track Details',
              scene: 'Scene',
              add: 'Create new',
              addTrackTo: 'Add track to:',
              noResults: 'No Results',
              title: 'Title',
              artist: 'Artist',
              type: 'Type',
              savePerformance: 'Save & Add New Performance',
              saveTrack: 'Save & Add Track',
              libraryType: 'Library Name',
            },
            scenePicker: {
              label: 'Production',
              disclosureButton: 'Select Scene/Asset',
              cueContainerRequired: 'Select a scene/asset within a production',
              noResults: 'No matches found',
              noAssetSelected: 'Select a scene/asset in order to add to a production.',
              searchPlaceholder: 'Find a scene/asset within a production',
            },
          },
          createLicensors: {
            disclosureButton: 'Add rights holders',
            licensors: 'Licensors',
            master: 'Master',
            one_stop: 'One Stop',
            sync: 'Sync',
            other: 'Other',
            writers: 'Writers',
            writer: 'Writer',
            publishers: 'Publishers',
            masterLicensor: 'Master Licensor',
            syncLicensor: 'Sync Licensor',
            licensorNameRequired: 'Please enter a name to add a licensor',
            publisher: 'Publisher',
            licensorName: 'Licensor Name',
            totalShare: 'Total Share',
            form: {
              save: 'Save',
              successMessage: 'Licensors created successfully',
              master: {
                name: 'Master Licensor Name',
              },
              one_stop: {
                name: 'One Stop Licensor Name',
              },
              sync: {
                name: 'Sync Licensor Name',
              },
              other: {
                name: 'Other Licensor Name',
              },
            },
          },
          notification: {
            addTrack: 'Track was added',
            createTrack: 'Track {{name}} has been created',
          },
        },
      },
      firstNameLastName: {
        placeholderFirstName: 'First Name',
        placeholderLastName: 'Last Name',
      },
      nameForm: {
        placeholder: 'Name',
      },
      fileAttachments: {
        drawerTitle: 'File Attachment',
        fileName: 'File Name',
        fileType: 'File Type',
        clearance: 'Clearance',
        licensor: 'Licensor',
        qclearLicensorStatusId: 'Set Licensor Status To',
        quoteExpiration: 'Quote Expiration',
        description: 'Description',
        fileAttachedTo: 'File Attached To {{title}}',
        chooseFileName: 'Choose A File Name',
        chooseFileType: 'Choose A File Type',
        chooseClearance: 'Choose A Clearance',
        chooseStatus: 'Choose A Status',
        bundled: 'Bundled',
        noLicensor: 'No Licensor',
        uploadText: '<0>TO UPLOAD A FILE</0> <1>DRAG AND DROP</1> <2>OR CLICK TO OPEN FILE VIEWER</2>',
        confirmDismiss: 'Are you sure you want to cancel this attachment?',
      },
      settings: {
        rights: {
          territory: {
            title: 'Select Territory',
            presetName: 'Preset Name',
            populateLetter: 'Populate Letter As',
          },
        },
      },
      contacts: {
        drawerTitle: 'New Contact',
        drawerEditTitle: 'Edit Contact',
        drawerViewTitle: 'View Contact',
        form: {
          firstName: 'Contact First Name',
          lastName: 'Contact Last Name',
          companyName: 'Company Name',
          licensor: 'Licensor',
          rightsOwnerType: 'Rights Owner Type',
          selectType: 'Select Type...',
          communication: 'Communication',
          email: 'Email',
          type: 'Type',
          number: 'Number',
          assistantFirstName: 'Assistant First Name',
          assistantLastName: 'Assistant Last Name',
          addressLine1: 'Address Line 1',
          addressLine2: 'Address Line 2',
          city: 'City',
          state: 'State/Prove',
          zipCode: 'Zip/Postcode',
          country: 'Country',
          payee: {
            title: 'Payee Information',
            type: {
              label: 'Type',
              company: 'Company',
              individual: 'Individual',
            },
            companyName: 'Company Name',
            dba: 'DBA',
            tin: 'TIN #',
          },
        },
      },
      clearance: {
        rightsPresetPicker: {
          label: 'Production Rights',
          placeholder: 'Select',
          createCustom: 'Create Custom',
        },
        fullList: {
          name: 'Name',
          asset: 'Asset',
          scene: 'Scene',
          sceneNumber: 'Scene #',
          assetNumber: 'Asset #',
          sceneName: 'Scene Name',
          assetName: 'Asset Name',
          using: 'Using',
          reqUsage: 'Req. Usage',
          actUsage: 'Act. Usage',
          feeAllocation: 'Fee Allocation',
          title: 'Cue Management',
          activateConfirmation: 'Are you sure you want to activate {{name}}?',
          deactivateConfirmation: 'Are you sure you want to deactivate {{name}}?',
          deactivated: '"{{name}}" has been deactivated',
          activated: '"{{name}}" has been activated',
          deleteConfirmation: 'Are you sure you want to delete {{name}}?',
          deleted: '"{{name}}" has been deleted',
          deleteClearance: 'Delete Clearance',
          editClearance: 'Edit Clearance',
          deleteCue: 'Delete Cue',
          makeActive: 'Make Active',
          makeInactive: 'Make Inactive',
          newOption: 'New Option',
          newClearance: 'New Clearance',
        },
        addEdit: {
          drawerTitle: 'New Clearance',
          drawerEditTitle: 'Edit Clearance',
          form: {
            name: 'Clearance Name',
          },
          setToOneHundred: 'Set to 100%',
          totalFees: 'Total Fees',
          clearanceGrandTotal: 'Clearance Grand Total',
          emptyCuesMessage: "There're no active Cues to show",
          sceneNumber: 'Scene #',
          assetNumber: 'Asset #',
          sceneName: 'Scene Name',
          assetName: 'Asset Name',
          using: 'Using',
          usage: 'Usage',
          associatedFee: 'Associated Fee',
          percentage: 'Percentage',
          requiredRights: 'Clearance Rights',
          sceneOrAsset: 'Scene or Asset',
        },
        option: {
          drawerTitle: 'New Option',
          drawerEditTitle: 'Edit Option',
          territoryTitle: 'Territory',
          form: {
            name: 'Option name',
            date: 'Rights Date',
            start: 'Rights Start Date',
            end: 'Rights End Date',
            fee: 'Option Fees',
            duration: 'Duration',
            use: 'Usage',
            requested: 'Requested',
            details: 'Details',
            fullUse: 'Full Use Duration',
          },
        },
        excercise: {
          drawerTitle: 'Exercise Option',
          form: {
            name: 'Option Name',
            createClearance: 'Create a new Clearance',
            addToClearance: 'Add to existing Clearance',
            fee: 'Option Fees',
            territory: 'Territory',
            dateRange: 'Date Range',
            associatedCues: 'Associated Cues',
          },
        },
        validations: {
          allocationsMax: 'Sum of all cues allocations cannot be higher than 100%',
          allocationsMin: 'Sum of all cues allocations cannot be lower than 0%',
        },
      },
      scenarioManagerAddEdit: {
        scenarioTitle: 'Version Title',
        description: 'Description',
        scenarioDetails: 'New Version',
        lastUpdatedBy: 'Last Updated by',
        createdBy: 'Created by',
      },
      versionManagerAddEdit: {
        delete: 'Delete',
        lastUpdatedBy: 'Last Updated by',
        createdBy: 'Created By',
        versionTitle: 'Version Title',
        drawerTitle: 'New Version',
        drawerEditTitle: 'Edit Version',
        version: 'Ver #',
        duration: 'TRT',
        currentVersion: 'Current Version',
        notes: 'Notes',
        confirmDelete: 'Are you sure you want to delete ?',
        items: {
          finishingVendors: 'Finishing Vendors',
          finishers: 'Finishers',
          creativeVendors: 'Creative Vendors',
        },
        notifications: {
          update: {
            success: 'Version Updated successfully',
          },
          create: {
            success: 'Version Created successfully',
          },
        },
      },
      versionManagerDuplicate: {
        copy: 'Copy',
        drawerTitle: 'Duplicate Version',
        versionTitle: 'Version Title',
        assetTitle: 'Asset Title',
        assetType: 'Asset Type',
        assetRequiredRight: 'Asset Required Right',
        currentVersion: 'Current Version',
        version: 'Ver #',
        duration: 'TRT',
        currentAsset: 'Current Asset',
        newAsset: 'New Asset',
        notes: 'Notes',
        unsavedWarning: 'You cannot create a new asset with unsaved changes in the current one',
        items: {
          finishingVendors: 'Finishing Vendors',
          finishers: 'Finishers',
          creativeVendors: 'Creative Vendors',
        },
      },
      completeMaterial: {
        title: 'Complete Asset',
        finishes: 'Finishes',
        assetTitle: 'Asset Title',
        version: 'Current Version',
        chooseAction: 'Choose an Action',
        use: 'Use',
        deactivate: 'Deactivate',
        materialFees: 'Material Fees',
        feesApply: 'Apply Fees',
        notes: 'Notes',
        dateUsed: 'Completed Date',
        finisher: 'Finisher',
        addFinisher: 'Add Finish',
        isci: 'ISCI #',
        finishingDate: 'Finishing Date',
        tooltip: 'Available Finishers from Asset Details settings',
        update: {
          success: 'Material Updated Successfully',
        },
        complete: {
          success: 'Asset Completed Successfully',
        },
        confirmCancel: 'Are you sure you want to cancel?',
      },
      tasks: {
        create: {
          createTask: 'Add Task',
          editTask: 'Edit Task',
          task: 'Task',
          form: {
            subject: 'Subject',
            recipientUserId: 'Assignee',
            dueDate: 'Due Date',
            description: 'Track Description (optional)',
            requestor: 'Requestor',
            createdDate: 'Created Date',
          },
          comments: 'Comments',
          noComments: "There're no comments for this Task",
          addComment: 'Add Comment',
          notification: {
            complete: {
              success: 'Task Completed Successfully',
              failed: 'There was an error while completing the task',
            },
            uncomplete: {
              success: 'Task Uncompleted Successfully',
              failed: 'There was an error while completing the task',
            },
          },
        },
      },
      inviteUsers: {
        buttonInvite: 'Invite',
        descriptionHint:
          'People are invited to qwireSuite via their email address. To invite several people at once, add multiple email addresses below',
        drawerTitle: 'Invite Users to Division',
        labelUserEmails: 'User Emails',
        notifications: {
          multipleInvite: {
            success: 'All Invites Were Sent Successfully',
            failed: 'There was an error while sending some of the Invites, please try again',
          },
        },
        repeatedOnDrawer: 'Email is duplicate on this form',
        repeatedOnDivision: 'Email already invited to this division',
      },
      shareWithUser: {
        drawerTitle: 'Share With A User',
        divisionAdmin: 'Division Admin',
        roleChanged: 'Role changed successfully',
        changingRoleFailed: 'Error while trying to change user role',
        accessRemoved: 'Access removed successfully',
        removingAccessFailed: 'Error while trying to remove access',
        productionUsersTable: {
          user: 'User',
          email: 'Email',
          role: 'Role',
        },
        labels: {
          user: 'User',
          users: 'Users',
          permissions: 'Permissions',
          role: 'Role',
          searchUserPlaceholder: 'Start typing...',
        },
        notifications: {
          cancel: 'Cancel',
          ok: 'OK',
          seasonEpisodePrompt: 'By inviting users to this episode, you are granting them access to the entire season.',
          seasonReleasePrompt:
            'By inviting users to this season release, you are granting them access to the entire season.',
          singleInvite: {
            success: 'Invite Sent Successfully',
            failed: 'There was an error while sending the Invite, please try again',
          },
        },
        placeholders: {
          searchByName: 'Search by name',
        },
      },
      usageNotes: {
        drawerTitle: 'Other Usage',
        label: 'Usage Description',
        notifications: {
          success: 'Usage Updated Successfully',
          failed: 'There was an error while updating usage, please try again',
        },
      },
      rightPreset: {
        customRight: 'Custom Rights',
        hints: {
          [RIGHTS_HINT_MESSAGE_TYPE.PRODUCTION]: 'This will only affect the Rights for this Production',
          [RIGHTS_HINT_MESSAGE_TYPE.SEASON]: 'This will only affect the Rights for Productions in this Series',
          [RIGHTS_HINT_MESSAGE_TYPE.SEASON_RELEASE]: 'This will only affect the Rights for Productions in this Release',
          [RIGHTS_HINT_MESSAGE_TYPE.CLEARANCE]: 'This will only affect the Clearance Rights for this Clearance',
        },
      },
      licensorsAddEdit: {
        searchContact: 'Search Contact',
        contact: {
          contact: 'Contact',
          firstName: 'First Name *',
          lastName: 'Last Name *',
          email: 'Email *',
          territory: 'Territory',
          currency: 'Currency',
          number: 'Number',
          type: 'Type',
          address1: 'Address Line 1',
          address2: 'Address Line 2',
          city: 'City',
          state: 'State',
          zipCode: 'Zip Code',
          country: 'Country',
        },
        subLabel: {
          subLabel: 'SubLabel',
          name: 'Sub Label Name',
          labelId: 'Label ID#',
          newSubLabel: 'New Sub-Label',
          addNew: 'Add New',
          deleteSubLabel: 'Delete Sub-Label',
          promptRemoveSubLabel: 'Are you sure you want to delete the Sub Label?',
          updateContactPrompt: 'Are you sure you want to update the Contact information?',
        },
        basicInfo: {
          repeatedLicensor: 'This licensor already exists in the Track',
          deleteLicensor: 'Delete Licensor',
          deletedLicensor: 'Licensor Deleted ',
          deleteLicensorPrompt: 'Are you sure you want to delete the Licensor?',
          master: {
            name: 'Master Licensor Name',
          },
          one_stop: {
            name: 'One Stop Licensor Name',
          },
          sync: {
            name: 'Sync Licensor Name',
          },
          other: {
            name: 'Other Licensor Name',
          },
          share: 'Share',
          providesOwnLicense: 'Provides Own License',
          updateLicensorNamePrompt: 'Are you sure you want to update the Licensor name?',
          updateContactPrompt: 'Are you sure you want to update the Contact information?',
        },
        sync: {
          addTitle: 'Add New Sync',
          editTitle: 'Edit Sync',
          success: 'Sync Licensor successfully edited',
          prompt: 'Are you sure you want to cancel?',
        },
        other: {
          addTitle: 'Add New Other',
          basicInfo: 'Basic Info',
          editTitle: 'Edit Other',
          success: 'Other Licensor successfully edited',
          prompt: 'Are you sure you want to cancel?',
          subLabel: 'Sub-Label',
        },
        master: {
          addTitle: 'Add New Master',
          editTitle: 'Edit Master',
          basicInfo: 'Basic Info',
          subLabel: 'Sub-Label',
          success: 'Master Licensor successfully edited',
          prompt: 'Are you sure you want to cancel?',
        },
        one_stop: {
          addTitle: 'Add New One Stop',
          editTitle: 'Edit One Stop',
          basicInfo: 'Basic Info',
          success: 'One Stop Licensor successfully edited',
          prompt: 'Are you sure you want to cancel?',
          subLabel: 'Sub-Label',
        },
      },
      changeClearanceRep: {
        title: 'Add Assignee',
      },
      clearanceUsesAndDurations: {
        title: 'Add Use',
        reqDuration: 'Requested Duration',
        reqUsage: 'Requested Usage',
        actDuration: 'Actual Duration',
        actUsage: 'Actual Usage',
        reqUseAndDuration: 'Requested Usage & Duration',
        actUseAndDuration: 'Actual Usage & Duration',
        actualUsageNotes: 'Actual Usage Notes',
        requestedUsageNotes: 'Requested Usage Notes',
        addNew: 'Add New',
        scene: 'Scene',
        moveMessage: 'The usage has been moved to {{scene}}.',
      },
      clearanceOtherUseAndDuration: {
        drawerTitle: 'Other',
        usageNotes: 'Description',
      },
      licensingFilters: {
        title: 'Filters',
        division: 'Division',
        production: 'Production',
        track: 'Track',
        licensor: 'Licensor',
        enterProductionName: 'Enter Production Name',
        enterTrackName: 'Enter Track Name',
        enterLicensorName: 'Enter Licensor Name',
        status: 'Status',
        clearFilters: 'Clear Filters',
        promptClearFilters: 'Are you sure you want to clear all filters?',
        productionType: 'Production Type',
      },
      bundle: {
        title: 'See Bundled Cues',
        unBundle: 'UNBUNDLE',
        titleArtist: 'Track Title / Artist',
        production: 'Production',
        usage: 'Usage',
        cues: 'CUES',
      },
      payeesAddEdit: {
        addTitle: 'New Payee',
        editTitle: 'Edit Payee',
        delete: 'DELETE PAYEE',
        companyName: 'Company Name',
        name: 'Name',
        share: 'Share of Payment',
        firstName: 'Payee First Name',
        lastName: 'Payee Last Name',
        email: 'Payee Email',
        address1: 'Address Line 1',
        address2: 'Address Line 2',
        city: 'City',
        state: 'State',
        zipCode: 'Zip Code',
        country: 'Country',
        tin: 'TIN #',
        dba: 'DBA',
        vendorNumber: 'Vendor Number',
        deletePrompt: 'Are you sure you want to delete?',
        notifications: {
          update: 'Payee successfully updated',
          create: 'Payee successfully created',
          delete: 'Payee successfully deleted',
        },
        updateContactPrompt: 'Are you sure you want to update the Contact information?',
      },
      cueRightOwnersPreset: {
        titleSave: 'Save Preset',
        titleApply: 'Apply Preset',
        newPresetLabel: 'New Preset Name',
        overwritePreset: 'Are you sure you want to overwrite this preset?',
      },
      cueSheetExport: {
        title: 'Export',
        selectFileType: 'Select File Type',
        includeNotes: 'Include Notes',
        addSegmentInfo: 'Include Cue Sheet Segments',
        cueSheet: 'Cue Sheet',
      },
      cueSheetSync: {
        title: 'Compare with Clearance',
        fallback: 'No tracks to sync',
        changes: '{{count}} CHANGE',
        changes_plural: '{{count}} CHANGES',
        howTo1: 'Choose the match the cues on the Cue Sheet with the existing Clearance information. For each row:',
        howTo2: 'Selecting “Clearance Data” will copy over the track data to the matched Cue',
        howTo3: ' Selecting “Cue Sheet Data” will not copy any changes',
        trackItem: {
          missingCue: 'No Match',
          reqUse: 'Req use:',
          actualUse: 'Actual Use',
          writers: 'Writer',
          publishers: 'Publisher',
          artists: 'Artist:',
          outOfSyncWithClearance: 'Out of Sync with Clearance',
          outOfSyncWithCueSheet: 'Out of Sync with Cue Sheet',
          requested: 'REQUESTED',
          actual: 'ACTUAL',
        },
        warning: 'Please execute the Compare with Clearance action from the cue sheet action menu.',
        outOfSyncMsg: 'This Cue Sheet is out of sync with Clearance',
        selectAll: 'SELECT ALL',
        clearData: 'Clearance Data',
        cueData: 'Cue Data',
      },
      cueSheetCompare: {
        qTracksData: 'qTracks Data',
        cueSheetData: 'Cue Sheet Data',
        title: 'Sync With QwireTracks',
      },
      cueSheetSegment: {
        segmentNameLabel: 'Segment Name',
        editTitle: 'Edit Segment',
        createTitle: 'Create Segment',
        removeSegmentLabel: 'Remove Segment',
      },
      quickSetupProduction: {
        drawerHowCreateProjectTitle: 'Click to learn how to create a project',
        drawerDefaultTitle: 'Create Production',
        drawerExportSegmentsTitle: 'Export Segments',
        campaign: 'Step 4: Enter Campaign Name',
        release: 'Step 4: Enter Release Name',
        releaseTV: 'Step 5: Enter Release Name',
        requiredProduction: 'Required to Create Production',
        cueSheetOnly: 'Cue Sheet Only',
        clearanceOnly: 'Clearance Only',
        releaseToolTip:
          'The Release level allows you to separate out different projects/seasons ' +
          'if you need to clear them differently. For example, you may have a US ' +
          'Release and a different International Release of a project. For many ' +
          'users, you can accept the default value and ignore this level.',
        releaseRequiredRights: 'Production Rights & Options',
        releasePlaceholder: 'Worldwide Release',
        episode: 'Episode',
        episodeNumber: 'Episode Number',
        episodeName: 'Episode Name',
        season: 'Step 4: Enter Season Number',
        seasonRequiredRights: 'Production Rights & Options',
        synopsis: 'Synopsis',
        category: 'Default Category',
        duration: 'Default Duration',
        watchProduction: 'Add Production to Dashboard',
        whereNext: {
          title: 'Where do you want to go?',
          continueToNew: 'Continue to new',
          continueToExisting: 'To the exported segments',
          goBack: 'Return to current',
        },
        exportSegmentSteps: {
          campaign: 'Campaign',
          release: 'Release',
          season: 'Season',
          exportToLabel: 'Export to',
          exportToAssetLabel: 'Export to Asset',
          existingOnly: 'To export segments to a marketing campaign, choose an existing project.',
          export: 'Export',
          noAssetsMessage: "This marketing campaign doesn't have any assets apart from the unassigned bin.",
          noEpisodesMessage: "This season release doesn't have any episodes.",
        },
        addNewEpisode: 'ADD EPISODE',
        oneOrMoreEpisodes: 'Step 6: Add One or More Episodes',
      },
      customStatuses: {
        title: 'Statuses',
        edit: 'Edit',
        save: 'Save',
      },
      rightsDrawer: {
        title: 'Edit Rights',
        newRights: 'New Rights',
        newOption: 'New Option',
        editOption: 'Edit Option',
        rightsPreset: 'Presets',
        rightsName: 'Name',
        rightsNamePlaceholder: 'Enter a Name',
        descriptionOnLetters: 'On Letter As',
        addTerritoryAndTerm: 'Add Territory & Term',
        removeTerritoryAndTerm: 'Remove Territory & Term',
        territory: 'Territory',
        territoryPlaceholder: 'Select a Territory',
        term: 'Term',
        termPlaceholder: 'Select a Term',
        start: 'Start',
        end: 'End',
        otherDetails: 'Other Details',
        to: 'to',
        rightsSaved: 'Rights saved successfully',
        optionsSaved: 'Option saved successfully',
        failed: 'Error while saving changes',
      },
      unionDrawer: {
        addTitle: 'Add Union',
        editTitle: 'Edit Union',
        name: 'Union',
        status: 'Status',
        useType: 'Type',
        fee: 'Fee',
        feeCoveredByLabel: 'Label Pays',
        notes: 'Notes',
        unionSaved: 'Union saved successfully',
      },
    },
    login: {
      title: 'Welcome to Qwire',
      content: 'Please log in to your account.',
      logIn: 'Log in',
      lockedContentFirst: 'Your screen has been locked for security purposes',
      lockedContentSecond: 'Sign back in to resume your session.',
      loginError: 'There was an error while logging in',
      trademark: '©{{currentYear}} Qwire Inc. All Rights Reserved.',
    },
    episodeDetail: {
      inactive: 'INACTIVE',
    },
    productionDetail: {
      inactive: 'INACTIVE',
    },
    account: {
      title: 'Create your account',
      subtitle: 'You’ve been invited to join the Qwire family. Enter your name to get started.',
      partner: {
        firstSubtitle: 'You’re signing up for Qwire through {{partner}}.',
        secondSubtitle: 'Please complete this page to use qwireSuite.',
        fieldsSectionText1: 'Qwire lets you work with episodic and non-episodic projects.',
        fieldsSectionText2: 'We suggest accepting the default names below. You can always change them later.',
        fieldsSectionHint: '(at least 1 is required)',
        footer: 'If you already have a Qwire account you can still use it with APM by completing this form.',
        login: 'Otherwise, click here to Log In.',
        divisionError: 'Division already exists',
        signUpComplete: 'Sign Up Complete',
        signUpCompleteEmailSent: 'A verification email has been sent.',
        signUpCompleteInbox: 'Please check your inbox to confirm the email used for Qwire.',
        emailConfirmedQuestion: 'Email confirmed?',
        emailNotReceived:
          'Did not receive the verification email? Try checking your Junk or Spam folder. You can contact us at support@qwire.com.',
      },
      fields: {
        firstName: 'First Name',
        lastName: 'Last Name',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        email: 'Email Address',
        seriesEpisodic: 'Series (Episodic)',
        nonEpisodic: 'Non-Episodic',
      },
      validations: {
        password: {
          min: 'Use {{min}} or more characters with a mix of letters, numbers & symbols',
          matches: 'Those passwords didn’t match. Try again.',
        },
      },
      successMessage: 'User Registration is complete. Welcome to qwireSuite',
      haveAccount: 'Already have an account?',
      login: 'LOG IN',
      continue: 'Continue',
    },
    apmSignup: {
      accept1: 'By creating an account, you agree to our',
      accept2: 'and have read and acknowledge the',
      createAccount: 'Create Account',
      createAccountDescription: 'Create your account or',
      login: 'log in',
      terms: 'Terms',
      globalPrivacy: 'Global Privacy Statement',
      title: 'Sign up for Qwire',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      companyName: 'Company Name',
      password: 'Password',
      signUpCompleted: 'Thanks for signing up for Qwire. You can now log in with your new credentials.',
      oneLower: 'One lowercase character',
      oneUpper: 'One uppercase character',
      oneNumber: 'One number',
      oneSpecial: 'One special character',
      atLeast8Char: '8 characters minimum',
    },
    projects: {
      welcome: 'Welcome',
      title: 'Projects',
      newProduction: {
        cancel: 'Cancel',
        createNew: 'Create New',
        division: 'Select a Division',
        next: 'Next',
        production: 'Production Name',
        title: 'New Project',
        projectTitle: 'Step 3: Enter Project Name',
        divisionTitle: 'Step 1: Select a Division',
        productionType: 'Step 2: Select a Production Type',
        divisionDescription: {
          belongMoreThanOne: 'If you belong to more than one division, select the division for this production.',
          belongOnlyOne: 'If you only belong to one division, proceed to step 2.',
        },
        productionDescription: {
          features: 'A non-Episodic, "one-off" project such as a feature film. A Feature has a single set of rights and a single cue sheet.',
          marketing: 'A marketing campaign contains many assets (e.g. trailers, promos, etc). Each asset has its own set of rights and its own cue sheet.',
          tv: 'A group of related productions, such as a series with multiple episodes. A TV project contains a season, a season release, and one or more episodes. Each episode has one set of rights and one cue sheet.',
        },
        projectDescription: {
          createNewProject: 'To create a new Project, enter the name of your Project.',
          addAdditionalProduction: 'To add additional Productions to an existing Project (e.g. new episodes), select the project from the dropdown.',
          viewOrEditProject: 'If you are trying to view or edit the Project, please close this drawer and click the "Find Project" button to locate the Project.',
        },
      },
      tabs: {
        watching: {
          title: 'Watching ({{count}})',
        },
        files: {
          title: 'Files',
        },
        trackHistory: {
          title: 'Track History',
        },
      },
      productions: 'Productions',
      newProject: 'Add Project',
      projectHierarchy: {
        noResults: 'To add your first project, click the Add Project button.',
        title: 'Production Title',
        mixDate: 'Mix Date',
        airReleaseDate: 'Release Date',
        actions: 'Actions',
        Season: 'Season',
        menuButtons: {
          viewEditSeason: 'View/Edit Season Details',
          viewEditRelease: 'View/Edit Release Details',
          viewEditProject: 'View/Edit Project Details',
          viewEditProduction: 'View/Edit Production Details',
          add: 'Add {{division}}',
          addRelease: 'Add Release',
          addEpisode: 'Add Episode',
          share: 'Share',
          archive: 'Archive',
          unwatch: 'Unwatch Production',
          watch: 'Watch Production',
          notifications: 'Notifications',
          notification: {
            watch: 'Production watched',
            unwatch: 'Production unwatched',
          },
          error: 'Error',
          features: 'Add Release',
          television: 'Add Season',
          marketing: 'Add Campaign',
        },
      },
      watchingTab: {
        unwatch: 'Remove from Watching?',
        filters: {
          clearance: 'Clearance',
          cueSheet: 'Cue Sheet',
          findProject: 'Find Project',
          licensing: 'Licensing',
          projectName: 'Project Name',
          releaseDate: 'Air / Release Date',
          sortBy: 'Sort By',
          status: 'Status',
        },
        notifications: {
          unwatched: 'Production removed from the watching list successfully',
        },
        noResults:
          '<0>You are not watching any productions.</0><1>You can watch productions by clicking the</1> <2/> <3>next to any production name.</3>',
        labels: {
          actions: {
            addEpisode: 'Add Episode',
            addAsset: 'Add Asset',
            archive: 'Archive',
            notifications: 'Notifications',
            share: 'Share',
            viewDetails: 'View Details',
            unwatchProduction: 'Stop Watching',
            activateProduction: 'Activate Production',
            inactivateProduction: 'Make Production Inactive',
            activateEpisode: 'Activate Episode',
            inactivateEpisode: 'Make Episode Inactive',
          },
          asset: {
            assets: 'ASSETS ({{count}})',
            countPending: '({{count}} Pending)',
            countPending_plural: '({{count}} Pending)',
            count: '{{count}} Asset',
            count_plural: '{{count}} Assets',
            creativeVendor: 'Creative Vendor',
            fees: 'Total Approved Fees',
            title: 'Asset Title',
            type: 'Asset Type',
            versionNumber: 'Ver #',
            versionName: 'Version Name',
          },
          airReleaseDate: 'Air/Release Date',
          assetsPending: 'Pending',
          budget: {
            productionBudget: 'Production Budget',
            committedFee: 'Committed Fee',
            uncommittedFee: 'Uncommitted Fee',
          },
          cueStatus: {
            new: 'New',
            inProgress: 'In Prog',
            cleared: 'Cleared',
            confirmed: 'Confirmed',
            licensed: 'Licensed',
            other: 'Other',
            tracksTotal: 'Tracks Total',
          },
          productionStatus: 'Status',
          quotesExpiring: 'Quotes Expiring',
          licensesExpiring: 'License Expiring',
          checksNotPaid: 'Checks Not Paid',
          status: 'Status',
          cuesWithErrors: 'Cues With Errors',
          mixDate: 'Mix Date',
          totalCues: 'Total Cues',
          incompleteCues: 'Incomplete Cues',
        },
      },
    },
    importCueSheet: {
      title: 'Import Spreadsheet',
      stepper: {
        1: 'Select File and Template',
        2: 'Select Header Row',
        3: 'Select First Row of Data',
        4: 'Map Data',
        5: 'qwireTracks Compare',
        6: 'Preview',
        7: 'Import',
      },
      hints: {
        hint: 'Hint: ',
        2: 'Click on the row from your file that contains the labels for your columns, and then click the next button.',
        3: 'Click on the first row that contains cue information, and then click the next button.',
        4: 'Select a column from your file below, and then click the fields from the list below that match. Do this for each column, and then click the NEXT button.',
        5: 'We have found matching tracks information in our qwireTracks database. Select the tracks below that you would like to import using qwireTracks information.',
        6: 'The table below is a preview of your import data. Click the back button to correct any import errors, or click import to proceed to the editable cue sheet.',
      },
      mapData: {
        loadingCompare:
          'One moment while we search qwireTracks for any potential matches. If we find a match, you can choose to import the information that we already have in qwireTracks.',
        availableFields: 'Available Fields',
        clearFields: 'Clear Fields',
        importPreview: 'Import Preview',
        selectedFields: 'Selected Fields',
        selectedFieldsHint: 'Click the fields to the left that match up with the information in your selected cell.',
        usage: {
          ignore: 'Ignore',
          importedUsage: 'Imported Usage',
          mapTo: 'Map To',
        },
        selectToStart: 'You must select a column to start mapping fields',
        goBackNotification: 'This will remove all mapped columns. Proceed?',
        unmappedNotification: 'You have not mapped all columns. Do you still wish to proceed?',
        usageMapping: 'Usage Mapping',
        fields: {
          general: 'General',
          timing: 'Timing',
          rightsHolders: 'Rights Holders',
          dividers: 'Dividers',
          [IMPORT_FIELDS.CUE_NUMBER]: 'Cue Number',
          [IMPORT_FIELDS.CUE_TITLE]: 'Cue Title*',
          [IMPORT_FIELDS.USAGE]: 'Usage',
          [IMPORT_FIELDS.ARTIST]: 'Artist',
          [IMPORT_FIELDS.TRACK_ID]: 'Track ID',
          [IMPORT_FIELDS.TIME_IN]: 'Time In',
          [IMPORT_FIELDS.TIME_IN_HH]: 'Time In HH',
          [IMPORT_FIELDS.TIME_IN_MM]: 'Time In MM',
          [IMPORT_FIELDS.TIME_IN_SS]: 'Time In SS',
          [IMPORT_FIELDS.TIME_OUT]: 'Time Out',
          [IMPORT_FIELDS.TIME_OUT_HH]: 'Time Out HH',
          [IMPORT_FIELDS.TIME_OUT_MM]: 'Time Out MM',
          [IMPORT_FIELDS.TIME_OUT_SS]: 'Time Out SS',
          [IMPORT_FIELDS.DURATION]: 'Duration',
          [IMPORT_FIELDS.DURATION_MM]: 'Duration MM',
          [IMPORT_FIELDS.DURATION_SS]: 'Duration SS',
          [IMPORT_FIELDS.ROLE]: 'Role',
          [IMPORT_FIELDS.WRITER]: 'Writer',
          [IMPORT_FIELDS.WRITER_PRO]: 'PRO Writer',
          [IMPORT_FIELDS.WRITER_SHARE]: 'Share Writer',
          [IMPORT_FIELDS.WRITER_FIRST_NAME]: 'Writer First',
          [IMPORT_FIELDS.WRITER_LAST_NAME]: 'Writer Last',
          [IMPORT_FIELDS.PUBLISHER]: 'Publisher',
          [IMPORT_FIELDS.PUBLISHER_PRO]: 'PRO Publisher',
          [IMPORT_FIELDS.PUBLISHER_SHARE]: 'Share Publisher',
          [IMPORT_FIELDS.WRITER_PUBLISHER]: 'Writer/Publisher',
          [IMPORT_FIELDS.WRITER_PUBLISHER_PRO]: 'PRO Writer/Publisher',
          [IMPORT_FIELDS.WRITER_PUBLISHER_SHARE]: 'Share Writer/Publisher',
          [IMPORT_DIVIDERS.SLASH]: '/',
          [IMPORT_DIVIDERS.OPEN_PARENTHESIS]: '(',
          [IMPORT_DIVIDERS.CLOSE_PARENTHESIS]: ')',
          [IMPORT_DIVIDERS.OPEN_SQUARE_BRACKET]: '[',
          [IMPORT_DIVIDERS.CLOSE_SQUARE_BRACKET]: ']',
          [IMPORT_DIVIDERS.DASH]: 'Dash',
          [IMPORT_DIVIDERS.SEMI_COLON]: ';',
          [IMPORT_DIVIDERS.SPACE]: 'Space',
          [IMPORT_DIVIDERS.COMMA]: 'Comma',
          colon: ':',
          period: 'Period',
        },
      },
      qtracksCompare: {
        advancedSearch: 'Advanced Search',
        artists: 'Artists:',
        goBackNotification: 'This will remove the information from this page. Proceed?',
        importedData: 'Imported Data',
        library: 'Library:',
        qtracksData: 'qwireTracks Data',
        selectAll: 'Select All',
        title: 'Title:',
        writers: 'Writers:',
      },
      preview: {
        cueNumber: 'Cue Number',
        duration: 'Duration',
        publishers: 'Publisher Affiliation',
        seqNumber: 'Sequence Number',
        timeIn: 'Time In',
        timeOut: 'Time Out',
        title: 'Title',
        usage: 'Usage',
        writers: 'Writers/Composers Affiliation',
        prompt: {
          noImportFile: 'No, import file',
          saveTemplateContent:
            'After saving as a template, you can easily recall these settings for importing in the future.',
          saveTemplateTitle: 'Do you want to save this as a template?',
          yesCreateTemplate: 'Yes',
        },
      },
      notifications: {
        duplicatedTemplate: 'There is already a template named {{name}} for this division.',
        templateDoesntMatch: "The uploaded file doesn't match the selected template.",
      },
    },
    productionOverview: {
      global: {
        groupByScenes: 'Group by Scenes',
        groupByAssets: 'Group by Assets',
        scenes: 'Scenes',
        assets: 'Assets',
        tracks: 'Tracks',
        addScene: 'Add Scene',
        addAsset: 'Add Asset',
      },
      notifications: {
        unwatch: 'Production removed from Watching',
        watch: 'Production added to Watching',
      },
      tracksFilter: {
        all: 'ALL',
        filteredTracks_plural: '<0>Filtered Results</0> (<1>{{count}} Tracks</1>)',
        filteredTracks: '<0>Filtered Results</0> (<1>{{count}} Track</1>)',
      },
      header: {
        intlRelease: 'International Release',
        mixDate: 'Mix Date',
        usRelease: 'US Release',
        assets: 'Assets',
        campaign: 'Campaign',
        details: 'Details',
        scenes: 'Scenes',
        scenario: 'Scenario',
        tracks: 'Tracks',
        versions: 'Versions',
        viewModes: 'View Modes',
        workflow: 'Workflow',
        menu: {
          addAsset: 'Add Asset',
          editAsset: 'Edit Asset',
          addScene: 'Add Scene',
          editScene: 'Edit Scene',
          addTrack: 'Add Track',
          archive: 'Archive',
          createTask: 'Create Task',
          currencies: 'Currencies',
          delete: 'Delete',
          duplicate: 'Duplicate',
          edit: 'Edit',
          hideInactiveCues: 'Hide Inactive Cues',
          hideInactiveTracks: 'Hide Inactive Tracks',
          share: 'Share',
          unwatch: 'Unwatch Production',
          viewInactiveCues: 'View Inactive Cues',
          viewInactiveTracks: 'View Inactive Tracks',
          watch: 'Watch Production',
          filters: {
            ALL: 'ALL',
            ARTIST: 'ARTIST',
            TRACKS: 'TRACKS',
            LICENSORS: 'LICENSORS',
          },
        },
      },
      footer: {
        progress: 'PROGRESS',
        statusNew: 'New',
        statusInProg: 'In Prog',
        statusConfirmed: 'Confirmed',
        statusLicensed: 'Licensed',
        totalEstFees: 'TOTAL EST. FEES (USING)',
        totalAppFees: 'TOTAL APP. FEES (USING)',
      },
      tabs: {
        licensing: {
          title: 'Licensing',
        },
        versions: {
          title: 'Versions',
        },
        budget: {
          addCost: 'ADD A COST',
          assignTo: 'ASSIGN TO',
          advanced: 'Advanced',
          budgetOverUnder: 'Over/Under',
          budgetType: 'Budget Type',
          committedFees: 'Committed Fees',
          totalCommitted: 'Total Committed',
          licenseFees: 'License Fees',
          displayAdditionalCurrency: 'Display Additional Currency',
          domesticBudget: 'Domestic Budget',
          domesticSplit: 'Domestic Split',
          edit: 'Edit',
          extraCosts: 'Extra Costs',
          extraCostsAmount: 'Amount',
          extraCostsDate: 'Date',
          extraCostsDescription: 'Description',
          extraCostsGrandTotal: 'GRAND TOTAL:',
          extraCostsProduction: 'Production',
          extraCostsTotal: 'Extra Cost Total',
          extraCostsTrack: 'Track',
          extraCostsConfirmDelete: 'Are you sure you want to delete extra cost?',
          fees: 'Fees',
          history: 'History',
          internationalBudget: 'International Budget',
          internationalSplit: 'International Split',
          musicBudget: 'Music Budget',
          simplified: 'Simplified',
          title: 'Budget',
          tooltipBudgetOverUnder: 'The difference between the total budget and committed fees',
          tooltipCommittedFees: 'The total of all approved fees of tracks marked as "Using"',
          tooltipDisplayAdditionalCurrency: 'View amounts exchanged in another currency',
          tooltipProductionBudget: 'Total budget of the production',
          tooltipTotalPotentialSpend: 'Total of all fees',
          tooltipUncommittedFees: 'Total approved fees of all non-using tracks',
          totalPotentialSpend: 'Total Potential Spend',
          uncommittedFees: 'Uncommitted Fees',
          errorFetchingAdditionalCurrencies: 'There was an error fetching additional currencies',
          form: {
            split: 'Please enter a number between 0 and 100',
          },
        },
        cueSheet: {
          menu: {
            addCue: 'Add Cue',
            attachFile: 'Attach File',
            export: 'Export Cue Sheet',
            hideInOut: 'Hide In/Out',
            importFile: 'Import Cue Sheet',
            share: 'Share',
            showInOut: 'Show In/Out',
            submit: 'Submit',
            syncWithClearance: 'Compare with Clearance',
          },
          title: 'Cue Sheet',
          emptyState:
            "<0>This marketing campaign doesn't have</0><1>any assets apart from the unassigned bin.</1><2>Create assets to start using cue sheets.</2>",
          header: {
            addRep: 'Add Assignee',
            assets: 'Assets',
            version: 'Version',
            rep: 'Assignee',
            status: 'Status',
            additionalNotes: 'Additional Notes',
            additionalNotesPlaceholder: 'Enter additional notes here...',
            cueSheetIds: 'Cue Sheet IDs',
            qwireCueSheetId: 'Qwire Cue Sheet ID',
          },
          licensorRow: {
            role: 'Role',
            writerComposer: 'Writer/Composer',
            publisher: 'Publisher',
            pro: 'PRO',
            share: 'Share',
            sync: 'Sync Licensor',
            updateContactPrompt: 'Are you sure you want to update the Contact information?',
          },
          cueSheetTable: {
            selectAll: 'SELECT ALL',
            action: 'ACTION',
            menu: {
              applyPreset: 'Apply Preset',
              changeCueDetails: 'Change Cue Details',
              createSegment: 'Create Segment',
              exportSegments: 'Export Segments',
              deleteSegmentsPrompt:
                'Are you sure you want to delete the selected segments? (Cues will NOT be deleted.)',
              delete: 'Delete',
              deleteSegments: 'Delete Segments',
              duplicate: 'Duplicate',
            },
            consecutiveCues: 'To create a segment, you must select one or more consecutive cues and no segments.',
            segmentsOnly: 'Make sure you have one or more segments selected and no cues.',
            emptyMessage:
              'This cue sheet doesn’t have any cues. You can add cues manually by clicking the Add Cue button. Or you can import a spreadsheet or EDL by choosing Import Cue Sheet from the Cue Sheet Action menu',
            reorderedCue: 'Cue Reordered',
          },
          cueRow: {
            addNew: 'Add New',
            cancel: 'CANCEL',
            distribute: '% DISTRIBUTE SHARES',
            saveCue: 'SAVE CUE',
            saveCueNext: 'SAVE CUE & NEXT',
            saveCueNew: 'SAVE & ADD NEW',
            writersComposers: 'Writers/Composers',
            publishers: 'Publishers',
            notes: 'Notes',
            library: 'Library',
            cancelPrompt: 'There are unsaved changes. Do you want to save?',
            total: 'TOTALS',
            addArtists: 'ADD ARTISTS',
            createNew: 'Create New',
            cueComesFromClearance: 'Cue from Clearance',
            errors: {
              rapid_cue_requires: 'RapidCue requires',
              no_duration: 'Duration',
              no_usage: 'Usage',
              no_writer_or_publisher: 'Writer or Publisher',
              writers_share_percentage:
                'RapidCue requires Writers have roles and that the Writer and Publisher shares be 0% or 100%',
            },
          },
          cueRowHeader: {
            seqNumber: 'Seq#',
            cueNumber: 'Cue#',
            trackType: 'Track Type',
            title: 'Title*',
            artist: 'Artist',
            usage: 'Use',
            in: 'In',
            out: 'Out',
            duration: 'Duration',
          },
          track: {
            completeEmpty: 'Complete Empty Fields',
            overwriteAll: 'Overwrite All Cue Info',
            promptConfirm: 'What do you want to do?',
            promptUpdate: "You're about to update your cue using qwireTracks information.",
            qTracksSync: 'qwireTracks Sync',
          },
          cueRowMenu: {
            delete: 'Delete',
            deleteCuePrompt: 'Are you sure you want to delete {{trackTitle}} from the cue sheet?',
            deleteCuesPrompt: 'Are you sure you want to delete {{count}} cue from the cue sheet?',
            deleteCuesPrompt_plural: 'Are you sure you want to delete {{count}} cues from the cue sheet?',
            viewDetails: 'View Details',
            duplicate: 'Duplicate',
            applyPreset: 'Apply Preset',
            savePreset: 'Save Preset',
            export: 'Export',
          },
          tracksTitleMenu: {
            title: 'Title',
            artists: 'Artist(s)',
            id: 'ID',
            writers: 'Writers(s)',
            library: 'Library',
            sourceLibrary: 'Source Library',
          },
          tracksTitleOption: {
            master: 'Master(s)',
            publisher: 'Publisher(s)',
            writer: 'Writer(s)',
          },
        },
        details: {
          menu: {
            projectDetails: 'View/Edit Project Details',
            seasonDetails: 'View/Edit Season Details',
            releaseDetails: 'View/Edit Release Details',
          },
          masterCurrencyExchange: '1 {{code}}',
          addedCurrencies: 'Added Currencies',
          additionalCurrencies: 'Additional Currencies',
          cueSheetBy: 'Cue Sheet Prepared By',
          additionalInformation: 'Additional Information',
          cueSheetByCountryCode: 'Country Code',
          cueSheetByEmail: 'Email',
          cueSheetByLastName: 'Last Name',
          cueSheetByName: 'First Name',
          releasePlaceholder: 'Worldwide Release',
          cueSheetByPhone: 'Phone',
          masterCurrency: 'Master Currency',
          descriptors: 'Rating Descriptor',
          description: 'Description',
          descriptorsPlaceholder: 'Select a descriptor',
          eidr: 'EIDR #',
          edit: 'Edit',
          duration: 'Duration',
          intlReleaseDate: 'Intl Release',
          version: 'Version',
          category: 'Category',
          language: 'Language',
          territory: 'Territory',
          releaseInformation: 'Release Information',
          productionInformation: 'Production Information',
          personnel: 'Personnel',
          isan: 'ISAN #',
          mixDate: 'Mix Date',
          notification: {
            campaign: {
              create: 'Campaign {{name}} has been created',
              edit: 'Campaign {{name}} has been updated',
            },
            episode: {
              create: 'Episode {{name}} has been created',
              edit: 'Episode {{name}} has been updated',
            },
            release: {
              create: 'Film Release {{name}} has been created',
              edit: 'Film Release {{name}} has been updated',
            },
          },
          notes: 'Notes',
          productionYear: 'Production Year',
          rating: 'Rating',
          ratingsPlaceholder: 'Select a rating',
          requiredRights: 'Production Rights',
          rights: 'Rights',
          title: 'Details',
          usReleaseDate: 'US Release',
          cueSheetInstructions: 'Cue Sheet Instructions',
          currency: 'Currency',
          features: {
            number: 'Release number',
            productionTypeTitle: 'Release Information',
            internalId: 'Internal Production ID #',
            prompt: 'Are you sure you want to exit setup?',
            title: 'Release Title',
          },
          television: {
            number: 'Episode Number',
            animated: 'Animated',
            productionTypeTitle: 'Episode Information',
            internalId: 'Internal Episode Production ID #',
            title: 'Episode Title',
          },
          marketing: {
            productionTypeTitle: 'Campaign Information',
            internalId: 'Internal Production Number',
            title: 'Campaign Title',
            number: 'Campaign Number',
            prompt: 'Are you sure you want to exit setup?',
          },
          items: {
            actors: 'Actors',
            directors: 'Directors',
            distributors: 'Distributors',
            editors: 'Editors',
            keyPersonnel: 'Key Personnel',
            networkBroadcaster: 'Network/Broadcaster',
            productionCompanies: 'Production Companies',
            producers: 'Producers',
          },
          confirmRightsUpdateForProduction: 'Do you want to apply these new rights to ALL tracks in this production?',
          rightsAndOptionsTitle: 'Rights & Options',
          addRight: 'Add Rights',
          addOption: 'Add Option',
          onLetterAs: 'On Letter As',
        },
        tasks: {
          buttons: {
            addTask: 'Add Task',
            inbox: 'Inbox',
            sent: 'Sent',
            completed: 'Completed',
            myTeam: 'My Team',
          },
          headers: {
            assignee: 'Assignee',
            subject: 'Subject',
            trackTitleArtist: 'Track/Title Artist',
            createdDate: 'Created Date',
            dueDate: 'Due Date',
          },
          title: 'Tasks',
        },
        trackHistory: {
          columns: {
            production: 'Production',
            scene: 'Scene',
            releaseYear: 'Release year',
            use: 'Use',
            duration: 'Duration',
            fee: 'Fee',
          },
        },
        clearanceLog: {
          columns: {
            user: 'User',
            ipAddress: 'IP Address',
            timestamp: 'Date Timestamp',
            changes: 'Changes',
          },
          changes: {
            'Requested Use Type': 'Requested Use Type <1>set to</> <2><0>{{value}}</0></2>',
            'Actual Use Type': 'Actual Use Type <1>set to</1> <2><0>{{value}}</0></2>',
            'EMI Film and Television Music Licensor Share %':
              'EMI Film and Television Music Licensor Share % <1>set to</1> <2><0>{{value}}</0></2>',
          },
        },
        overview: {
          title: 'Overview',
          scenes: {
            addCueContainer: 'Add Scene',
            addToTemplate: 'Add To Template',
            cues: '{{count}} Cue',
            cues_plural: '{{count}} Cues',
            addTrack: 'Add Track',
            editCueContainer: 'Edit',
            move: 'Move',
            deletePrompt: 'Are you sure you want to delete scene?',
            movedMessage: 'The scene was moved successfully',
            deletedMessage: 'The scene was deleted successfully',
            deleteCueContainer: 'Delete',
            deleteDisabled: 'You may not delete a scene that contains tracks.',
            notes: 'NOTES',
            popover: {
              moveAfterCueContainer: 'Move after Scene',
            },
            headers: {
              actions: 'Actions',
              actualUse: 'Actual',
              approvedFees: 'Approved Fees',
              estimatedFees: 'Estimated Fees',
              using: 'Using',
              rep: 'Rep',
              tags: 'Tags',
              requestedUse: 'Requested',
              title: 'Track Title/Artist',
              trackStatus: 'Status',
              type: 'Type',
            },
            hints: {
              sceneHint: {
                emptyCueContainer: 'This scene is empty or has inactive cues',
                gotIt: 'Got It',
                subtitle:
                  'You can drag a song from the Unassigned Bin or add them to this scene with the add track button.',
                title: 'New scene helpful hint',
              },
              unassignedHint: {
                addingCueContainer: 'Add scenes and add tracks into your scenes',
                addingTracks: 'Just start adding tracks.',
                addCueContainer: 'Add Scene',
                addTrack: 'Add Track',
                emptyBin: 'The Unassigned Bin is empty  or has inactive cues',
                subtitle: 'There are 2 ways to get things started',
                title: 'You’ve got a brand new project',
              },
            },
          },
          assets: {
            currentVersion: '{{title}} V{{number}} - {{name}}',
            addCueContainer: 'Add Asset',
            addTrack: 'Add Track',
            addVersion: 'Add Version',
            editCueContainer: 'Edit',
            deactivated: 'Deactivated',
            deletePrompt: 'Are you sure you want to delete asset?',
            deletedMessage: 'The asset was deleted successfully',
            movedMessage: 'The asset was moved successfully',
            deleteCueContainer: 'Delete',
            deleteDisabled: 'You may not delete an asset that contains tracks.',
            feesApplied: 'Fees Applied',
            complete: 'Complete Asset',
            move: 'Move',
            notes: 'Notes',
            cues: '{{count}} Cue',
            cues_plural: '{{count}} Cues',
            requiredRights: 'Production Rights',
            using: 'Using',
            popover: {
              moveAfterCueContainer: 'Move after Asset',
            },
            headers: {
              actions: 'Actions',
              actualUse: 'Actual',
              approvedFees: 'Approved Fees',
              estimatedFees: 'Estimated Fees',
              using: 'Using',
              rep: 'Rep',
              requestedUse: 'Requested',
              tags: 'Tags',
              title: 'Track Title/Artist',
              trackStatus: 'Status',
              type: 'Type',
            },
            footer: {
              total: 'Asset Total',
            },
            hints: {
              assetHint: {
                emptyCueContainer: 'This asset is empty',
                gotIt: 'Got It',
                subtitle:
                  'You can drag a song from the Unassigned Bin or add them to this asset with the add track button.',
                title: 'New asset helpful hint',
              },
              unassignedHint: {
                addingCueContainer: 'Add assets and add tracks into your assets',
                addingTracks: 'Just start adding tracks.',
                addCueContainer: 'Add Asset',
                addTrack: 'Add Track',
                emptyBin: 'The Unassigned Bin is empty',
                subtitle: 'There are 2 ways to get things started',
                title: 'You’ve got a brand new project',
              },
            },
          },
          unassigned: {
            cues: '{{count}} Cue',
            cues_plural: '{{count}} Cues',
            title: 'Unassigned',
            addTrack: 'Add Track',
          },
          tracks: {
            addTag: 'Add a Tag',
            addTrack: 'Add Track',
            emptyHint: {
              title: 'You’ve got a brand new project',
              subtitle: 'In tracks view you can add, sort and filter tracks',
            },
            filter: 'Filter by track names, artists, licensors',
            overview: 'Overview',
            headers: {
              actions: 'Actions',
              actualUse: 'Actual',
              approvedFees: 'App. Fees',
              estimatedFees: 'Est. Fees',
              using: 'Using',
              rep: 'Rep',
              tags: 'Tags',
              requestedUse: 'Requested',
              title: 'Track Title/Artist',
              trackStatus: 'Status',
              type: 'Type',
              BLANK: '',
            },
            menu: {
              bookmark: 'Bookmark',
              trackDetail: 'Track Detail',
              createTask: 'Create Task',
              currencies: 'Currencies',
              deactivate: 'Make Inactive',
              reactivate: 'Make Active',
              removeBookmark: 'Remove Bookmark',
              moveTrack: 'Move Track',
              sendLetters: 'Send Letters',
              compare: 'qwireTracks Compare',
              attachFile: 'Attach File',
              addToTemplate: 'Add To Template',
              markAsNotUsing: 'Mark As Not Using',
              markAsUsing: 'Mark As Using',
            },
          },
        },
        files: {
          title: 'Files',
        },
      },
    },
    files: {
      title: 'Files',
      attachments: 'Attachments',
      mail: 'Mail',
      licensor: 'Licensor',
      fileType: 'Type',
      addAttachment: 'Add Attachment',
      allOption: 'All',
      uploadText: '<0>TO UPLOAD A FILE</0> <1>DRAG AND DROP</1> <2>OR CLICK TO OPEN FILE VIEWER</2>',
      table: {
        createdAt: 'Date',
        fileName: 'File Name',
        fileType: 'File Type',
        licensorName: 'Licensor',
        fileDescription: 'Description',
        trackTitleArtist: 'Track Title / Artist',
        clearanceName: 'Clearance',
        fileSize: 'File Size',
      },
      // @TODO below translations will be adjusted removed in CLR-2601
      attachFile: 'Attach file',
      fileName: 'File Name',
      description: 'Description',
      clearance: 'Clearance',
      fileSize: 'File Size',
      date: 'Date',
      actions: 'Actions',
      confirmDelete: 'Are you sure you want to delete file?',
      notification: {
        create: 'File was added',
        update: 'File {{name}} was updated',
      },
    },
    mail: {
      from: 'From',
      letterType: 'Letter Type',
      sentAt: 'Date',
      subject: 'Subject',
      track: 'Track Title/Artist',
    },
    settings: {
      title: 'Settings',
      divisionButton: 'Division Settings',
      userButton: 'My Settings',
      divisionDropdown: {
        placeholder: 'Select a division',
      },
      tabs: {
        auditLog: 'Audit Log',
        divisions: 'Division Info',
        rights: 'Rights Presets',
        territory: 'Territory Presets',
        users: 'Users',
        contacts: 'Contacts',
        notifications: 'Notifications',
        letterTemplates: 'Letter Templates',
        scheduleATemplates: 'Schedule A Templates',
        emailTemplates: 'Email Templates',
        cueSheet: 'Cue Sheet',
        ariaLabel: 'Settings tabs',
      },
      division: {
        companyDetails: 'Company Details',
      },
      contactsPreference: {
        title: 'Preferred Contact List',
        description:
          'For qwireSuite users who have access to more than one division, you may choose which division’s contact list you would like to use.',
        dropdownLabel: 'Select a Division',
        dropdownPlaceholder: 'Please select a division',
      },
      cueSheet: {
        title: 'Cue Sheet Details',
        subtitle: 'Select the PROs that will appear by default when submitting a cue sheet:',
        successMessage: 'Division settings updated successfully',
        warningMessage:
          'Missing Sender ID and/or Sender Name. Please ask a Division Admin to complete these fields. For help contact support@qwire.com',
        rapidCueInformation: 'Rapid Cue Information',
        senderID: 'Sender ID',
        senderName: 'Sender Name',
        senderType: 'Sender Type',
        defaultCategory: 'Default Category',
        companyID: 'Company ID',
        companyName: 'Company Name',
        defaultDuration: 'Default Duration',
        company: {
          title: 'Company Contact for RapidCue',
          firstName: 'First Name',
          lastName: 'Last Name',
          contactEmail: 'Contact Email',
          telephone: 'Telephone',
          address1: 'Address Line 1',
          address2: 'Address Line 2',
          city: 'City',
          state: 'State/Prov',
          zip: 'Zip/Postcode',
          country: 'Country',
        },
      },
      users: {
        name: 'Name',
        role: 'Default Project Role',
      },
    },
    auditLog: {
      filter: {
        applyFilters: 'Apply',
        filters: 'Filters',
        clearFilters: 'Clear',
        subprojects: {
          drawerTitle: 'Production Title',
          label: 'Production Title',
          searchPlaceholder: 'Search by Production Title',
          searchHint: 'You can search for productions',
        },
        users: {
          drawerTitle: 'Users',
          label: 'Users',
          searchPlaceholder: 'Search by User',
          searchHint: 'You can search for users',
        },
      },
      list: {
        changes: 'Changes',
        date: 'Date & Timestamp',
        ip: 'IP',
        production: 'Production',
        user: 'User',
        update: '<0>Updated </0><1>{{name}}</1><2> from </2><3>{{from}}</3><4> to </4><5>{{to}}</5>',
      },
    },
    territory: {
      header: {
        addNew: 'Add New Preset',
        search: 'Search by name',
        column: {
          territoryName: 'Territory Name',
          letterAs: 'Populate Letter As',
          territories: 'Territories',
          worldwide: 'Worldwide',
        },
      },
      row: {
        form: {
          name: {
            label: 'Region',
            placeholder: '',
          },
          nameOnLetters: {
            label: 'Letter as',
            placeholder: '',
          },
          strategy: {
            label: 'Territories',
            placeholder: 'Start Typing',
            values: {
              worldwide: 'Worldwide Excluding',
              specific: 'Specific Set',
            },
          },
          countryIds: {
            placeholder: 'Search for countries',
          },
        },
      },
      notification: {
        get: {
          failed: 'There was an error while getting a list of Territories',
        },
        add: {
          prompt: 'You have unsaved changes, are you sure you want to cancel?',
          success: 'Territory saved',
        },
        edit: {
          prompt: 'Are you sure you want to save? Updating this territory preset will update',
          success: 'Territory {{name}} updated',
          failed: "Couldn't update territory, please try again",
        },
        delete: {
          prompt: 'Are you sure you want to delete?',
          promptWithRights: 'Are you sure you want to save? Removing this territory preset will update',
          success: 'Territory was removed',
          failed: "Couldn't delete territory, please try again",
        },
      },
    },
    templates: {
      types: {
        letter: 'Letter',
        scheduleA: 'Schedule A',
        email: 'Email',
      },
      creator: {
        template: 'Template',
        tabs: {
          composeTemplate: {
            title: 'Compose Template',
            letterTemplateFields: 'Letter Template Fields:',
            scheduleATemplateFields: 'Schedule A Template Fields:',
            search: {
              placeholder: 'Search...',
              label: 'Search for Field',
            },
            letterInstructions: {
              what: {
                label: 'What:',
                text: 'Letter templates are used to automatically generate letters that are sent to rights holders.',
              },
              how: {
                label: 'How:',
                text: 'Compose your letter in the document editor to the right. You can insert track details and personalize the letter using the "Letter Template Fields” listed below. To insert a letter template field, place your cursor where you want the field to appear in the document and click on the field in the list below. Fields can be removed by deleting the text for the field in the letter.',
              },
            },
            schedule_aInstructions: {
              what: {
                label: 'What:',
                text: 'Schedule A templates are attached to letter templates to be sent to rights holders.',
              },
              how: {
                label: 'How:',
                text: 'Compose your Schedule A in the document editor to the right.',
              },
            },
            emailInstructions: {
              what: {
                label: 'What:',
                text: 'Email templates are used to automatically generate emails that are sent to rights holders.',
              },
              how: {
                label: 'How:',
                text: 'Compose your email in the email editor to the right. You can insert track details and personalize the email using the “Email Template Fields” listed below. To insert an email template field, place your cursor where you want the field to appear in the email and click on the field in the list below. Fields can be removed by deleting the text for the field in the letter.',
              },
            },
            form: {
              body: 'Body:',
              bodyPlaceholder:
                'e.g.\nHello ##ContactFirst##,\n\nPlease see the attached licensing document for "##TrackTitle##" by ##TrackArtist##.\n\nThank you!\n\n##ClearanceRepName##',
              cc: 'CC:',
              ccPlaceholder: 'e.g. someone@example.com',
              subject: 'Subject:',
              subjectPlaceholder: 'e.g. Quote request for ##LetterType##',
            },
          },
          templateDetails: {
            title: 'Template Details',
            nameYourTemplate: 'Name Your Template',
            preferredTooltip: 'This will be saved as primary if there are multiple saved templates with the same rules',
            form: {
              assignToProductions: 'Assign to Productions',
              assignToLicensors: 'Assign to Licensors',
              licensor: 'Licensor',
              preferred: 'Set as Primary',
              production: 'Production',
              letterType: 'Letter Type',
              licensorType: 'Licensor Type',
              next: 'Next: Compose Template',
              selectType: 'Select Type...',
              emailTitle: 'Email Template Name',
              letterTitle: 'Letter Template Name',
              schedule_aTitle: 'Schedule A Template Name',
              scheduleATemplate: 'Schedule A',
            },
            instructions: {
              letter: {
                what: {
                  label: 'What:',
                  text: 'Letter templates are used to automatically generate letters that are sent to rights holders.',
                },
                how: {
                  label: 'How:',
                  text: 'Start by giving your letter template a name and selecting the letter type that it applies to. Optionally, you can assign particular productions and licensors to restrict the template’s use.',
                },
              },
              email: {
                what: {
                  label: 'What:',
                  text: 'Email templates are used to automatically generate emails that are sent to rights holders.',
                },
                how: {
                  label: 'How:',
                  text: 'Start by giving your email template a name and selecting the email type that it applies to. Optionally, you can assign particular productions and licensors to restrict the template’s use.',
                },
              },
            },
          },
        },
        cancel: 'There are unsaved changes, are you sure you want to close?',
      },
      notifications: {
        delete: 'Template {{name}} deleted',
        deletePrompt: 'Are you sure you want to delete {{name}}?',
        duplicated: 'Template with name {{name}} already exists',
        save: 'Template {{name}} saved',
      },
      header: {
        addNewTemplate: 'Add New Template',
        search: {
          title: 'Search',
          placeholder: 'Search Templates',
          ariaLabel: 'Search',
        },
        filter: {
          title: 'Filter',
        },
      },
      columns: {
        primary: 'Primary',
        letterTemplateName: 'Letter Template Name',
        schedule_aTemplateName: 'Schedule A Template Name',
        emailTemplateName: 'Email Template Name',
        licensorType: 'Licensor Type',
        letterType: 'Letter Type',
        licensor: 'Licensor',
        project: 'Project',
        actions: 'Actions',
        attachedTo: 'Attached To',
        attachment: 'Attachment',
      },
      noTemplates: {
        letter: {
          title: "Looks like you don't have any Letter Templates created",
        },
        schedule_a: {
          title: "Looks like you don't have any Schedule A Templates created",
        },
        email: {
          title: "Looks like you don't have any Email Templates created",
        },
        body: 'You can create new templates using the <0>Add new Template</0> button <br> in the top right corner',
      },
    },
    licensors: {
      types: {
        master: 'Master',
        publisher: 'Publisher',
        one_stop: 'One Stop',
        other: 'Other',
        sync: 'Sync',
        writer: 'Writer',
      },
      functionOptions: {
        SIDE_ARTIST: 'Side Artist',
        ESTATE: 'Estate',
        LIKENESS: 'Likeness',
      },
    },
    rightsOwners: {
      types: {
        master: 'Master',
        publisher: 'Publisher',
        writer: 'Writer',
        composer: 'Composer',
      },
      roles: {
        N: 'N',
        AD: 'AD',
        AR: 'AR',
        C: 'C',
        CA: 'CA',
        SR: 'SR',
        SA: 'SA',
        PR: 'PR',
        TR: 'TR',
        A: 'A',
      },
    },
    phone: {
      types: {
        Phone: 'Phone',
        Mobile: 'Mobile',
        Office: 'Office',
        Fax: 'Fax',
      },
    },
    composeLetters: {
      stepper: {
        next: 'Next',
        send: 'Send',
        back: 'Back',
        skipToEmail: 'Skip to email',
        selectLicensor: 'Select Licensor',
        selectContact: 'Select Contact',
        composeLetter: 'Compose Letter',
        composeEmail: 'Compose Email',
        letterCurrentOfTotal: 'Letter {{current}} of {{total}}',
        queuedLetters: '<0>Queued Letters:</0><1></1><2>({{count}})</2>',
        singleLicensor: 'Single Licensor Letter',
        title: 'Letters Manager',
      },
      selectContact: {
        stepTitle: 'Select Contact',
        existingContactTab: 'Existing Contact',
        newContactTab: 'New Contact',
        selectContactMessage: 'Please enter the contact details before proceeding to the Compose Letter step.',
        existingContactLabel: 'Contact',
        existingContactRequired: 'Please select a contact',
        noExistingContacts: 'No contacts found',
        emailLabel: 'Email Address',
        firstNameLabel: 'First Name',
        lastNameLabel: 'Last Name',
        address1Label: 'Address Line 1',
        address2Label: 'Address Line 2',
        cityLabel: 'City',
        stateLabel: 'State',
        postalCodeLabel: 'Postal Code',
        countryNameLabel: 'Country',
      },
      letterTemplate: {
        stepTitle: 'Compose Letter',
        templateId: 'Letter Template',
        licensorStatusId: 'Licensor Status',
        letterFileType: 'Send File As',
        setStatus: 'Set Status',
        setSendDate: 'Set Send Date',
        emptyDocumentOption: 'Empty Document',
        downloadButton: 'Download',
      },
      composeEmail: {
        stepTitle: 'Compose Email',
        emptyEmailOption: 'Empty Email',
        templateLabel: 'Email Template',
        licensorStatusLabel: 'Licensor Status',
        toLabel: 'To',
        ccLabel: 'CC',
        subjectLabel: 'Subject',
        fromLabel: 'From',
        bodyLabel: 'Body',
      },
      attachments: {
        fieldLabel: 'Attachments',
        letterInfo: 'The letter you composed will be attached to the email.',
        noAttachments: 'No attachments found.',
      },
    },
    letters: {
      emptyState:
        '<0>Please choose a Letter Type and set</0><1>Filters to select the Licensors to whom</1><2>letters will be sent.</2>',
      types: {
        CHECK_REQUEST: 'Check Request',
        CONFIRMATION: 'Confirmation',
        LICENSE_REQUEST: 'License Request',
        LICENSE: 'License',
        new: 'New',
        edit: 'Edit',
        QUOTE_RENEWAL: 'Quote Renewal',
        QUOTE_REQUEST: 'Quote Request',
      },
      buttons: {
        SET_DATE: 'SET DATE',
        REMOVE_DATE: 'REMOVE DATE',
      },
      notifications: {
        completed: 'Great Job. All of your letters have been sent!',
        draft: 'Draft saved successfully',
        sent: 'Letter sent successfully',
        downloaded: 'Letter downloaded and updated successfully',
      },
      stepper: {
        addClearances: 'Add Clearances',
        backToFilters: 'Back to Filters',
        composeEmail: 'Compose Email',
        composeLetter: 'Compose Letter',
        nextStepLetters: 'Next Step: Letters',
        nextStepReviewQueue: 'Next Step: Review Queue',
        reviewQueue: 'Review Queue',
        selectContact: 'Select Contact',
        title: 'Letters Manager',
      },
      generate: {
        extraAttachments: {
          title: 'Attachments',
          attachFiles: 'ATTACH FILES',
        },
        letterCurrentOfTotal: 'Letter {{current}} of {{total}}',
        licensorStatus: 'Set Licensor Status',
        missingTags: {
          continue: 'Continue',
          skipLetter: 'Skip Letter',
          subtitle: 'The following information is missing for the selected template:',
          title: 'Missing Information',
        },
        quoteExpiring: {
          currentExpiration: 'Current Expiration',
          newExpiration: 'New Expiration',
        },
        queuedLetters: '<0>Queued Letters:</0><1></1><2>({{count}})</2>',
        singleLicensor: 'Single Licensor Letter',
        skip: 'Skip',
        skipPrompt: 'Are you sure you want to skip this Letter?',
        change: 'Change',
        close: 'Close',
        letter: 'Letter',
        composeLetter: {
          selectedOptions: 'Selected Options',
          composeEmail: 'COMPOSE EMAIL',
          noTemplate: 'No Template',
          download: 'DOWNLOAD',
          formats: {
            pdf: 'PDF',
            wordDoc: 'Word Doc',
          },
          letterFormat: 'Letter Format',
          letterTemplate: 'Letter Template',
          skipToEmail: 'SKIP TO EMAIL',
          setStatus: 'Set Status',
          setSendDate: 'Set Send Date',
        },
        composeEmail: {
          emailTemplate: 'Email Template',
          noTemplate: 'No Template',
          template: {
            attachment: 'Attachment:',
            body: 'Body:',
            cc: 'CC:',
            ccPlaceholder: 'e.g. someone@example.com',
            from: 'From:',
            subject: 'Subject:',
            subjectPlaceholder: 'e.g. Quote request',
            to: 'To:',
          },
        },
        contact: {
          back: 'Back',
          email: 'Email',
          licensor: 'Licensor',
          name: 'Contact Name',
          firstName: 'First Name',
          lastName: 'Last Name',
          contactEmail: 'Contact Email',
          address1: 'Address Line 1',
          address2: 'Address Line 2',
          city: 'City',
          country: 'Country',
          postalCode: 'Postal Code',
          state: 'State',
          skip: 'Skip to Email',
          next: 'Next Step',
        },
        steps: {
          contact: 'Select Contact',
          composeLetter: 'Compose Letter',
          composeEmail: 'Compose Email',
          editContact: 'Edit Contact',
          editLetter: 'Edit Letter',
          updateContactPrompt: 'Are you sure you want to update the Contact information?',
        },
        stepperFooter: {
          back: 'Back',
          next: 'Next',
          send: 'Send',
          skipToEmail: 'Skip to Email',
        },
      },
      clearancesList: {
        table: {
          bundle: 'BUNDLE',
          clearance: 'Clearance',
          licensor: 'Licensor',
          licensorType: 'Licensor Type',
          production: 'Production',
          season: 'Season',
          selectAll: 'SELECT ALL',
          status: 'Status',
          tooltip: 'You must select multiple tracks from the same licensor for bundling',
          trackArtist: 'Track Title  |  Track Artist',
          unBundle: 'UN-BUNDLE',
        },
        filters: {
          all: 'All',
          addClearances: 'Add Clearances',
          applyFilters: 'Apply',
          clearFilters: 'Clear',
          division: 'Division',
          filters: 'Filters',
          include: 'Include',
          singleLetter: 'Send a single letter',
          note: 'Note:',
          singleLicensorCaption:
            'When a single licensor is selected, you may can choose to combine the corresponding clearances into a single letter.',
          allTracks: 'All Tracks',
          letterType: 'Letter Type',
          productions: { label: 'Production' },
          licensors: {
            drawerTitle: 'Licensors',
            label: 'Licensor',
            searchPlaceholder: 'Search by Licensor',
            searchHint: 'You can search for licensors',
          },
          tracks: {
            drawerTitle: 'Track Title',
            label: 'Track',
            searchPlaceholder: 'Search by Track Title',
            searchHint: 'You can search for tracks',
          },
          status: {
            drawerTitle: 'Status Filter',
            label: 'Status',
          },
          library: {
            label: 'Library',
          },
          notUsing: 'Not Using',
          using: 'Using',
        },
      },
      stepTwo: {
        trackCounts:
          '<0>Selected Tracks:</0><1></1><2>{{countSelected}}</2><3>Total Tracks:</3><4></4><5>{{countTotal}}</5>',
        clearancesSelected: 'Selected Clearances',
        clearancesTotal: 'Total Clearances',
      },
    },
    writersComposers: {
      types: {
        AD: 'AD - Adapter',
        AR: 'AR - Arranger',
        C: 'C - Composer',
        CA: 'CA - Composer/Author',
        SR: 'SR – Sub-Arranger',
        SA: 'SA – Sub-Author',
        PR: 'PR - Associated Performer',
        TR: 'TR - Translator',
        A: 'A – Author, Writer, Lyricist',
      },
    },
    publishers: {
      types: {
        E: 'E - Original Publisher',
        SE: 'SE - Sub-Publisher',
        ES: 'ES - Substitute Publisher',
        PA: 'PA - Publisher Income Participant',
        AM: 'AM - Administrator',
      },
    },

    cueThemes: {
      types: {
        Bumper: 'Bumper',
        Closing: 'Closing',
        EndTitle: 'End Title',
        Logo: 'Logo',
        MainTitle: 'Main Title',
        Opening: 'Opening',
        Segment: 'Segment',
        Note: 'Note',
      },
    },
    cuesUsages: {
      types: {
        A_CAPELLA: 'A Capella',
        DANCE: 'Dance',
        HUMMING: 'Humming',
        INDISTINGUISHABLE: 'Indistinguishable',
        LIVE_PERFORMANCE_SYMPHONIC: 'Live Performance Symphonic',
        LYRIC_LESS_VOCAL: 'Lyricless Vocal',
        NOODLING: 'Noodling',
        SPOKEN: 'Spoken',
        WHISTLING: 'Whistling',
      },
    },
    rapidCueSender: {
      types: {
        CABLE_SOURCE: 'Cable Source',
        DISTRIBUTOR: 'Distributor',
        NETWORK: 'Network',
        PRODUCTION_COMPANY: 'Production Company',
        WRITER: 'Writer',
        PUBLISHER: 'Publisher',
        OTHER: 'Other',
      },
    },
    contacts: {
      typeahead: {
        createNew: 'Create New',
        email: 'Email',
        name: 'Contact Name',
      },
      deleted: 'Contact deleted',
      viewContact: 'View Contact',
      searchContactNameOrLicensor: 'Search contact name or licensor',
      confirmDelete: 'Are you sure you want to delete {{fullName}} from your contacts',
      newContact: 'NEW CONTACT',
    },
    rights: {
      drawer: {
        presetName: 'Preset Name',
        defaultLabel: 'Set as Default',
        presetRightsTitle: 'Add Preset Rights',
        presetOptionsTitle: 'Add Preset Options',
        saveAndCreateNewButton: 'Save & Create New',
      },
      grid: {
        preset: 'Preset',
        type: 'Type',
        territory: 'Territory & Term',
        descriptionOnLetters: 'On Letter As',
        default: 'Default',
        more: 'More',
        addOptionsPreset: 'Add Options Preset',
        addRightsPreset: 'Add Rights Preset',
      },
      header: {
        addNew: 'Add New Preset',
        search: 'Search',
      },
      details: {
        ONE_DAY: 'One Day',
        DATE_RANGE: 'Date Range',
        INPERPETUITY: 'In Perpetuity',
        OTHER: 'Other',
        from: 'From',
        to: 'to',
        noRights: 'No rights',
        editClearanceRights: 'Edit clearance rights',
      },
      row: {
        [RIGHTS_TERRITORIES_TERM.ONE_DAY]: 'One Day',
        [RIGHTS_TERRITORIES_TERM.DATE_RANGE]: 'Date Range',
        [RIGHTS_TERRITORIES_TERM.INPERPETUITY]: 'In Perpetuity',
        [RIGHTS_TERRITORIES_TERM.OTHER]: 'Other',
        nonSpecified: 'Non Specified',
      },
      rowExpanded: {
        selectTerritory: 'Select a Territory',
        otherDetails: 'Other Details',
        territory: 'Worldwide',
        territoryTitle: 'Territory',
        presetName: 'Preset Name',
        defaultPreset: 'Default Preset',
        descriptionOnLetters: 'On Letter As',
        mediaType: 'Media Type',
        allMediaTypes: 'All',
        materialType: 'Material Type',
        allMaterialTypes: 'All',
        term: 'Term',
        name: 'Name',
      },
      notification: {
        get: {
          failed: 'There was an error while getting a list of Rights',
        },
        add: {
          prompt: 'There are unsaved changes, are you sure you want to cancel?',
          success: 'Right Preset saved',
          failed: "Couldn't create right preset, please try again",
        },
        edit: {
          prompt: 'Are you sure you want to save?',
          success: 'Right Preset {{name}} updated',
          failed: "Couldn't update right preset, please try again",
          editDefault: 'Are you sure you want to change the default rights preset for this division?',
        },
        delete: {
          prompt: 'Are you sure you want to delete?',
          success: 'Right Preset was removed',
          failed: "Couldn't delete right preset, please try again",
        },
        territoryDrawer: {
          edit: {
            success: 'Territories updated',
            failed: "Couldn't update territories, please try again",
          },
          get: {
            failed: 'Error fetching territory',
          },
        },
      },
      loading: 'Loading Presets...',
    },
    tracks: {
      header: {
        uploadSuccess: 'Filed uploaded successfully',
        using: 'Using',
        status: 'Status',
        rep: 'Rep',
        tags: 'Tags',
        notes: 'Notes',
        uploadFile: 'UPLOAD FILE',
        edit: 'EDIT NOTES',
        editNotesPlaceholder: 'Click EDIT NOTES button to add notes',
        save: 'SAVE',
        cancel: 'CANCEL',
        continue: 'Are you sure you want to continue?',
        preCleared: 'Pre-Cleared',
        removePreCleared:
          'You can remove the pre-clearance designation only for this track (when pre-clearance is not for all projects), or for all other instances of this track in this division',
        confirmPreClearanceRemoval: 'REMOVE FOR ALL TRACKS',
        confirmPreClearanceRemovalOneTrack: 'REMOVE FOR THIS TRACK',
        preClearTitle: 'Pre-Clear This Track',
        preClear: 'To pre-clear this track for anyone in your division, select the term and assign dates.',
        term: 'Term*',
        termSelect: 'Select a Term',
        description: 'Description',
        enterDescription: 'Enter description',
        done: 'DONE',
        startDate: 'Start Date*',
        endDate: 'End Date*',
        to: 'to',
        clearedBy: 'Cleared by',
        dateRange: 'Date Range',
        perpetuity: 'In Perpetuity',
        allProjectsInfoLabel: 'All Projects',
        editPreCleared: 'Edit',
        startDateRequiredError: 'Start Date is required',
        startDateGreaterThanCurrentDateError: 'Start Date must be greater than or equal to the current date',
        endDateRequiredError: 'End Date is required',
        endDateGreaterThanStartDateError: 'End Date must be greater than the start date',
        termRequiredError: 'Term is required',
        allProjectsLabel: 'All Projects',
        allProjectsPlaceholder: 'All Projects',
        allProjectsRequiredError: 'You must check a option for All Projects',
        yes: 'Yes',
        no: 'No',
      },
      tabs: {
        tasks: {
          buttons: {
            addTask: 'Add Task',
            inbox: 'Inbox',
            sent: 'Sent',
            completed: 'Completed',
            myTeam: 'My Team',
          },
          headers: {
            assignee: 'Assignee',
            subject: 'Subject',
            createdDate: 'Created Date',
            dueDate: 'Due Date',
          },
        },
        titles: {
          clearances: 'Clearances',
          licensing: 'Licensing',
          details: 'Details',
          writersPublishers: 'Writer / Publisher',
          files: 'Files',
          useHistory: 'Use History',
          activity: 'Activity',
          tasks: 'Tasks',
        },
        trackDetails: {
          master: 'Master',
          sync: 'Sync',
          other: 'Other',
          actions: {
            addMaster: 'Add Master Licensor',
            addSync: 'Add Sync Licensor',
            addOther: 'Add Other Licensor',
          },
          trackInfoTitle: 'Track Info',
          licensorsTitle: 'Licensors',
          oneStop: 'One Stop',
          combined: 'Combined',
          separate: 'Separate',
          trackInfo: {
            title: 'Title',
            artists: 'Artists',
            albums: 'Albums',
            noResults: 'No results',
            duration: 'Duration',
            year: 'Year',
            isrc: 'ISRC',
            other: 'Other',
            explicit: 'Explicit',
            companyDetails: 'Company Details',
            arrangers: 'Arranger(s)',
            composers: 'Composer(s)',
            unionDetails: 'Union Details',
            noUnion: 'Non Union',
            adCardCredits: 'Ad Card / End Title Credits',
            publicDomain: 'Public Domain',
            mmss: 'MM:SS',
            master: 'Master',
            containSamples: 'Contains Samples',
            chooseOne: 'Choose One',
            publisher: 'Publisher',
            genres: ' Genre',
            newGenre: 'Create New Genre',
            selectGenre: 'Select Genre',
            notes: 'Genre Notes',
            additionalDetails: 'Additional Details',
            ownedRights: 'Studio Owned Master Rights',
            ownedSync: 'Studio Owned Sync Rights',
            createNewLibrary: 'Create New Library',
            copyrightTag: 'Copyright tag',
            soundEffects: 'Sound Effects',
            libraryTracks: 'Library Track',
            score: 'Score',
            tracks: 'Tracks',
            trackType: 'Track Type',
            libraryName: 'Library Name',
            lastUpdatedByUserName: 'Last updated by {{userName}} at {{date}}',
          },
          licensors: {
            remove: 'Remove',
            removePrompt: 'Are you sure you want to remove this licensor: {{name}}?',
            master: {
              name: 'Master Licensor Name',
              share: 'Share %',
              territory: 'Territory',
              ownLicense: 'Provides Own License',
              contactFirstName: 'Contact First Name',
              contactLastName: 'Contact Last Name',
              contactEmail: 'Contact Email',
              address1: 'Address Line 1',
              address2: 'Address Line 2',
              city: 'City',
              state: 'State/Prov',
              zipCode: 'Zip/PostCode',
              country: 'Country',
              addAnother: 'Add Master',
              remove: 'Remove',
              recordLabel: 'Sub-Label',
              payee: 'Payee',
            },
            sync: {
              addAnother: 'Add Sync',
              remove: 'Remove',
              name: 'Sync Licensor Name',
              share: 'Share %',
              territory: 'Territory',
              ownLicense: 'Provides Own License',
              contactFirstName: 'Contact First Name',
              contactLastName: 'Contact Last Name',
              contactEmail: 'Contact Email',
              address1: 'Address Line 1',
              address2: 'Address Line 2',
              ownerSince: 'Owner Since',
              city: 'City',
              state: 'State/Prov',
              zipCode: 'Zip/PostCode',
              country: 'Country',
              parentCompany: 'Parent Company',
            },
            other: {
              addAnother: 'Add Other',
              remove: 'Remove',
              name: 'Company Name',
              share: 'Share %',
              create: 'Create',
              noResults: 'No Results',
              territory: 'Territory',
              contactFirstName: 'Contact First Name',
              contactLastName: 'Contact Last Name',
              contactEmail: 'Contact Email',
              address1: 'Address Line 1',
              address2: 'Address Line 2',
              city: 'City',
              state: 'State/Prov',
              zipCode: 'Zip/PostCode',
              country: 'Country',
              function: 'Function',
              otherLicensorType: 'Other Licensor Type',
              company: 'Company',
              individual: 'Individual',
              payee: 'Receptor',
            },
            cueSheetPublishers: {
              title: 'Cue Sheet Publishers',
              addAnother: 'Add Another',
              remove: 'Remove',
              name: 'Publishing Company Name',
              territory: 'Territory',
              contactFirstName: 'Contact First Name',
              contactLastName: 'Contact Last Name',
              contactEmail: 'Contact Email',
              address1: 'Address Line 1',
              address2: 'Address Line 2',
              city: 'City',
              state: 'State/Prov',
              zipCode: 'Zip/PostCode',
              country: 'Country',
              company: 'Company',
              ipi: 'IPI #',
            },
            recordLabel: {
              name: 'Sub-Label Name',
              labelId: 'Label ID#',
              contactFirstName: 'Contact First Name',
              contactLastName: 'Contact Last Name',
              contactEmail: 'Contact Email',
              territory: 'Territory',
              address1: 'Address Line 1',
              address2: 'Address Line 2',
              city: 'City',
              state: 'State/Prov',
              zipCode: 'Zip/PostCode',
              country: 'Country',
              remove: 'Remove',
              addAnother: 'Add Another',
            },
            payee: {
              type: 'Type',
              company: 'Company',
              individual: 'Individual',
              name: 'Company Name',
              contactFirstName: 'Payee First Name',
              contactLastName: 'Payee Last Name',
              contactEmail: 'Contact Email',
              tinId: 'TIN #',
              dba: 'DBA',
              sharePayment: 'Share of Payment',
              address1: 'Address Line 1',
              address2: 'Address Line 2',
              city: 'City',
              state: 'State/Prov',
              zipCode: 'Zip/PostCode',
              country: 'Country',
              subtitle: 'Check Information',
              void: 'Void',
              checkNumber: 'Check Number',
              checkRequestSent: 'Check Request Sent',
              datePaid: 'Date Paid',
              remove: 'Remove',
              addAnother: 'Add Another',
              paymentAmount: 'Payment Amount',
            },
            oneStop: {
              title: 'One Stop Combined',
            },
          },
        },
        writersPublishers: {
          publishers: 'Publishers',
          writersComposers: 'Writers / Composers',
        },
      },
      notifications: {
        bookmarks: {
          created: 'Bookmark created',
          removed: 'Bookmark removed',
        },
        library: {
          create: {
            success: 'Library Created Successfully',
          },
        },
        genre: {
          create: {
            success: 'Genre Created Successfully',
          },
        },
        option: {
          create: {
            success: 'Option Created Successfully',
            failed: 'There was an error during Option creation, please try again',
          },
          update: {
            success: 'Option Updated Successfully',
            failed: 'There was an error while updating the Option, please try again',
          },
        },
        update: {
          success: 'Track Updated Successfully',
          failed: 'There was an error while updating the track',
        },
      },
    },
    clearanceDetails: {
      requestedUse: 'Requested Use',
      actualUse: 'Actual Use',
      updateSuccessMessage: 'Clearance Updated',
      sent: 'Sent',
      received: 'Received',
      expires: 'Expires',
      notes: 'Track Notes',
      edit: 'EDIT NOTES',
      editNotesPlaceholder: 'Add notes',
      save: 'SAVE',
      cancel: 'CANCEL',
      lastupdated: 'Last Updated:',
      mfnWarning: 'You may not change the MFN for this licensor.',
      mfn: {
        BASE: 'BASE',
        WAIVED: 'WAIVED',
        SET_MFN: 'SET MFN',
        WAIVE: 'WAIVE',
        RESET: 'RESET',
      },
      QUOTE_REQUEST: {
        sent: '<0>Quote</0> <1>Sent</1>',
        received: '<0>Quote</0> <1>Received</1>',
        expires: '<0>Quote</0> <1>Expires</1>',
      },
      CONFIRMATION: {
        sent: '<0>Confirmation</0> <1>Sent</1>',
        received: '<0>Confirmation</0> <1>Received</1>',
        expires: '<0>Confirmation</0> <1>Expires</1>',
      },
      LICENSE_REQUEST: {
        sent: '<0>License Request</0> <1>Sent</1>',
        received: '<0>License Request</0> <1>Received</1>',
      },
      manual: 'Manual',
      grandTotal: 'GRAND TOTAL:',
      headerConfirmation: 'Confirmation',
      headerFeeApproved: 'Approved Fees',
      headerFeesEstimated: 'Estimated Fees',
      headerLicense: 'License Request',
      headerMaster: 'Master',
      headerOneStop: 'One Stop',
      headerOther: 'Other',
      headerQuote: 'Quote',
      headerQuoteFull: 'Full Quote',
      headerShare: 'Share',
      headerSync: 'Sync',
      headerStatus: 'Status',
      tableMFN: 'MFN',
      tableTotals: 'TOTALS:',
      tableTotalsOption: 'OPTION TOTALS:',
      addLicensorButton: 'ADD LICENSOR',
      clearances: 'Clearances',
      rightsName: 'Rights:',
      dateRange: 'Date Range:',
      date: 'Date:',
      duration: 'Duration',
      requested: 'Requested',
      actual: 'Actual',
      addUse: 'Add Use',
      addTag: 'Add Tag',
      options: 'Options',
      fees: 'Fees:',
      copyButton: 'COPY ==>',
      exercise: 'Exercise',
      unexercise: 'Unexercise',
      optionExercisePrompt: 'Please confirm that you are exercising {{name}}.',
      optionUnexercisePrompt: 'Are you sure you want to unexercise this option?',
      optionUnexerciseWithLettersPrompt: 'Option is on letters. Are you sure you want to unexercise this option?',
      licensors: 'Licensors',
      details: 'Details',
      newOption: 'New Option',
      editClearances: 'Edit Clearances',
      newClearance: 'New Clearance',
      addNew: 'New',
      clickOn: 'Click ',
      toAddLicensor: 'to add Licensor',
      waived: 'Waived',
      headerName: 'Name',
      letters: 'Letters',
      lettersMenu: {
        CONFIRMATION: '{{newOrEdit}} Confirmation',
        edit: 'Edit',
        LICENSE_REQUEST: '{{newOrEdit}} License Request',
        CHECK_REQUEST: '{{newOrEdit}} Check Request',
        LICENSE: '{{newOrEdit}} License',
        new: 'New',
        QUOTE_RENEWAL: '{{newOrEdit}} Quote Renewal',
        QUOTE_REQUEST: '{{newOrEdit}} Quote Request',
      },
      letterPill: {
        QUOTE_SENT: 'QUOTE SENT',
        QUOTE_RECEIVED: 'QUOTE RCV',
        QUOTE_EXPIRED: 'QUOTE EXP',
        LICENSE_SENT: 'LIC SENT',
        LICENSE_RECEIVED: 'LIC RCVD',
        LICENSE_EXPIRED: 'LIC EXP',
        CHECK_REQUEST_SENT: 'CHK REQ SENT',
        CHECK_REQUEST_RECEIVED: 'CHK REQ RCVD',
        CONFIRMATION_SENT: 'CONF SENT',
        LICENSE_REQUEST_SENT: 'LIC REQ SNT',
        LICENSE_REQUEST_RECEIVED: 'LIC REQ RCV',
        VIEW_DATES: 'VIEW DATES',
        SET_DATES: 'SET DATES',
      },
      featuredArtistSampleBadge: 'Featured Artist / Sample',
      licensorsMenu: {
        addUnion: 'Add Union',
        markAsNonUnion: 'Mark as Non-Union',
        markAsFeaturedArtist: 'Mark as Featured Artist/Sample',
        unmarkFeaturedArtist: 'Unmark Featured Artist/Sample',
      },
      unionOneStopChangeDialogContent:
        'Unions will be removed for non-master licensors but will be kept for master licensors. Are you sure you want to proceed?',
      markAsFeaturedArtistMessage: 'Licensor set to Featured Artist/Sample for this track',
      unmarkFeaturedArtistMessage: 'Licensor is no longer set to Featured Artist/Sample for this track',
    },
    userManagement: {
      userPermissions: {
        headers: {
          globalPermissions: 'Global Permissions',
          productionList: 'Production List',
          tooltip:
            "List of projects and productions that the user's permission is DIFFERENT from their Default Project Role. Go to the Production > Share action to change or remove user's permission.",
          userBreadcrumb: 'Users',
          userDeactivated: 'DEACTIVATED',
        },
        buttons: {
          deactivateUser: 'DEACTIVATE USER',
          reactivateUser: 'REACTIVATE USER',
          resendInvite: 'RESEND INVITE',
        },
        labels: {
          defaultPermissions: 'Default Project Role',
          email: 'Email',
          firstName: 'First Name',
          lastName: 'Last Name',
          permissions: 'Permissions',
          phone: 'Phone',
          productionName: 'Production Name',
          title: 'Title',
        },
        tooltip:
          'This role will be granted to the user on ALL productions, unless they are overridden with a higher permission on specific productions. Set to NONE if you do not want to grant the user access to ALL Productions.',
        prompts: {
          deactivateUser: 'Are you sure you want to deactivate this user?',
          deletePermission: 'Are you sure you want to delete this permission?',
          reactivateUser: 'Are you sure you want to reactive this user?',
        },
        apiCall: {
          success: 'Invitation Resent Successfully',
          failed: 'There was an error during Invitation resend, please try again',
          permissionsUpdated: 'Permissions updated successfully',
          permissionsFailed: 'Permissions update failed',
        },
        globalPermissions: 'Global Permissions',
        divisionAdmin: 'This user is Division Admin and has access to all productions in this division.',
        defaultRoleDescription: 'This user is a {{role}} for all productions in this division.',
      },
    },
    userProfile: {
      password: {
        changePassword: 'Change password',
        labelConfirmPassword: 'Confirm New Password',
        labelCurrentPassword: 'Current Password',
        labelNewPassword: 'New Password',
        successMessage: 'Your password has been updated',
        title: 'Password',
      },
      personalDetails: {
        labelEmail: 'Email',
        labelFirstName: 'First name',
        labelLastName: 'Last name',
        labelPhone: 'Phone',
        labelTitle: 'Job Title',
        title: 'Personal Details',
      },
      signature: {
        title: 'Signature File',
        uploadSignature: 'Upload a signature',
      },
      supportedFileTypes: 'Supported file types: JPG, PNG',
      title: 'My Profile',
      uploadPicture: 'Upload a picture',
    },
    cueContainerVersionManager: {
      current: 'Current',
      assets: 'Assets',
      chooseAsset: 'Choose Asset',
      trackList: 'Track List',
      cueList: 'Cue List',
      cues: 'Cues',
      searchCues: 'Search Cues',
      searchCuesPlaceholder: 'Search Cues by Name',
      close: 'Close',
      populate: 'Populate',
      actionMenu: {
        markAsUsing: 'Mark as Using',
        edit: 'Edit',
        duplicate: 'Duplicate',
        populateFromCurrent: 'Populate from Current',
        makeCurrentVersion: 'Make Current Version',
        delete: 'Delete',
        confirmMakeCurrentVersion: 'This will update this version to be the current version',
        confirmPopulateFromCurrent:
          'Are you sure you want to replace all cues in this version with the cues from the current version?',
        confirmDelete: 'Are you sure you want to delete ?',
      },
      getStarted: 'To get started you can:',
      populateFirst: 'Take a shortcut and populate from the Project Overview or drag Cues in',
      notifications: {
        copy: {
          success: 'Asset copied successfully',
        },
        update: {
          success: 'Asset updated successfully',
        },
      },
      addVersion: 'Add Version',
    },
    scenarioManager: {
      tracksMarkedUsing: 'Tracks marked as Using',
      confirmCurrentContent:
        'By applying this scenario, the cue ordering in your project will be updated to match this scenario. Cues not in this scenario will be made inactive.',
      confirmCurrentTitle: 'Mark As Current',
      alphabetical: 'Alphabetical',
      cues: 'Cues',
      searchCuesPlaceholder: 'Search Cues by Name',
      close: 'Close',
      totals: 'Totals',
      searchCues: 'Search Cues',
      populate: 'Populate',
      getStarted: 'To get started you can:',
      populateFirst: 'Take a shortcut and populate from the Project Overview or drag Cues in',
      current: 'Current',
      estimated: 'Estimated',
      approved: 'Approved',
      copy: 'Copy',
      cueList: 'Cue List',
      scenes: 'Scenes',
      versions: 'Versions',
      addScenario: 'Add Version',
      actionMenu: {
        markAsUsing: 'Mark Tracks as Using',
        edit: 'Edit',
        duplicate: 'Duplicate',
        populateFromCurrent: 'Populate from Overview',
        makeCurrentVersion: 'Make Current',
        delete: 'Delete',
        confirmPopulateFromCurrent:
          'Are you sure you want to replace all cues in this version with the cues from the current version?',
        confirmDelete: 'Are you sure you want to delete ?',
      },
      notification: {
        update: 'Scenario updated successfully',
      },
    },
    qwireAdmin: {
      title: 'Qwire Admin',
      tenants: {
        audit: 'Audit Log',
        title: 'Tenant Listing',
        name: 'Name',
        tenantSearchPlaceholder: 'Search by tenant name',
        divisionSearchPlaceholder: 'Search by division name',
        divisions: 'Divisions',
        editLogo: 'Edit Logo',
        idleTimeout: 'Idle Timeout',
        tenantName: 'Tenant name',
        addDivision: 'Add Division',
        addTenant: 'Add Tenant',
        divisionUsers: 'Division Users',
        clearApp: 'Clear',
        cueApp: 'Cue',
        permissionsTableColumns: {
          defaultProjectRole: 'Default Project Role',
          canAccessClear: 'Can Access Clear',
          canAccessCue: 'Can Access Cue',
          isDivisionAdmin: 'Is Division Admin',
          viewUpdateFinantial: 'View & Update Financial Info',
          createUpdateContacts: 'Create & Update Contacts',
          viewReports: 'View Reports',
          viewTrackHistory: 'View All Track History',
          createUpdateRight: 'Create & Update Rights',
          createUpdateLetters: 'Create & Update Letters',
          createUpdateLetterTemplates: 'Create & Update Letter Templates',
          createTasks: 'Create Tasks',
          createProductions: 'Can Create Productions',
          features: 'Can Create Releases',
          marketing: 'Can Create Campaigns',
          television: 'Can Create Seasons',
        },
        userName: 'User Name',
        theLogo: 'The logo will appear in different sections of the application',
        activeApps: 'Active Applications',
        inviteUsers: 'Invite Users',
        inviteInfo:
          'IMPORTANT: By inviting a user and setting their Project Default Role, you are giving the user access to ALL Productions for this Division. If you only want to invite a user to a single Production, please navigate to that production and click “Share” in the Production action menu.',
        cueSheetPartner: {
          select: 'Select',
          title: 'Cue Sheet Partner',
          submitToTenant: 'Submit To Tenant?',
          removable: 'Removable?',
          preferredFileType: 'Preferred File Type',
          email: 'Email',
          electronic: 'Electronic',
        },
      },
      employees: {
        buttons: {
          addUser: 'Add User',
          deactivateUser: 'Deactivate User',
          reactivateUser: 'Reactivate User',
        },
        labels: {
          addUser: 'Add User',
          email: 'Email',
          emails: 'Email Address',
          firstName: 'First Name',
          lastName: 'Last Name',
          userDeactivated: 'DEACTIVATED',
          users: 'Users',
        },
        prompts: {
          deactivateUser: 'Are you sure you want to deactivate this user?',
          reactivateUser: 'Are you sure you want to reactive this user?',
        },
        searchPlaceholder: 'Search employees by name',
        title: 'Qwire Employees',
      },
      libraries: {
        title: 'Libraries',
        header: {
          title: 'Import a Library File',
          dropdownLabel: 'Library',
          dropdownPlaceholder: 'Select a Library',
          buttonCancel: 'Cancel',
          buttonImport: 'Import',
        },
        dropzone: {
          fileInputLabel: 'File',
        },
        notifications: {
          import: 'Library file successfully imported',
        },
      },
      rapidcue: {
        title: 'RapidCue',
        header: {
          title: 'RapidCue Connection Information',
          subTitle: 'RapidCue Login Credentials',
        },
        labels: {
          productionMode: 'Production Mode (switch off for testing mode)',
          pollInterval: 'Poll Interval (in minutes)',
          login: 'Login',
          password: 'Password',
        },
      },
    },
    divisionSettings: {
      divisionName: 'Division Name',
      billingDetails: 'Billing Details',
      billingContactName: 'Billing Contact',
      billingContactEmail: 'Billing Contact Email',
      billingContactPhone: 'Billing Contact Phone',
      billingContactAddress: 'Billing Contact Address',
      editStatuses: 'Edit Statuses',
      pathForDashboard: 'Path for Reporting Dashboard',
      pathForReports: 'Path for Reports',
      statuses: 'Statuses',
      theLogo: 'The logo will appear in different sections of the application',
      quoteExpirationNumberOfDays: 'Quote-Days until expire',
      licenseExpirationNumberOfDays: 'License-Days until expire',
    },
    tasks: {
      title: 'Tasks',
      tabs: {
        inbox: 'Inbox',
        sent: 'Sent',
        completed: 'Completed',
        myTeam: 'My Team',
      },
      columns: {
        assignee: 'Assignee',
        division: 'Division',
        subject: 'Subject',
        track: 'Track Title / Artists',
        production: 'Production',
        requestor: 'Requestor',
        created: 'Created Date',
        due: 'Due Date',
      },
      emptyHint: "There're no tasks to display",
    },
    notifications: {
      category: {
        /** @see src/redux-core/notifications/constants.js */
        filmAlerts: 'Film Alerts',
        general: 'General',
        marketingAlerts: 'Marketing Alerts',
        tvAlerts: 'Series Alerts',
        taskAlerts: 'Task Alerts',
        trackAlerts: 'Track Alerts',
      },
      defaultProjectName: 'Project Name',
      defaultCampaignName: 'Campaign Name',
    },
    reports: {
      title: 'Reports',
      selectDivision: 'Select a division',
      askForAI: 'Ask for AI',
      selectProductionType: 'Select a production type',
      selectReport: 'Select report',
      export: 'Export',
      division: 'Division',
      reportName: 'Report name',
      onErrorContent: 'There was a problem while trying to fetch reports',
    },
    globalSearch: {
      advancedSearch: 'Advanced Search',
      searchTerm: 'Search Term',
      filters: {
        divisions: 'Divisions',
        date: 'Date',
        startDate: 'Start Date',
        endDate: 'End Date',
        status: 'Status',
        division: 'Division',
        showArchived: 'Show Archived',
        showInactived: 'Show Inactive Productions',
        filterBy: {
          productions: 'Productions',
          licensors: 'Licensors',
          tracks: 'Tracks',
        },
        productionSearch: {
          PROJECT_NAME: 'Project Name',
          EPISODE_NAME: 'Episode Name',
          PRODUCTION_NUMBER: 'Production Number',
          CUE_SHEET_SEGMENT_NAME: 'Cue Sheet Segment Name',
        },
        trackSearch: {
          ARTIST: 'Artist',
          LIBRARY_NAME: 'Library Name',
          PUBLISHER_NAME: 'Publisher Name',
          TRACK_TITLE: 'Track Title',
          WRITER_NAME: 'Writer Name',
        },
        licensorSearch: {
          CONTACT_NAME: 'Contact Name',
          LICENSOR_NAME: 'Licensor Name',
          SUB_LABEL_NAME: 'Sub-label Name',
        },
        dateTypes: {
          ALL: 'All',
          INTL_RELEASE: 'Intl Release',
          US_RELEASE: 'Us Release',
        },
      },
      results: {
        approvedFees: 'Approved Fees',
        requestedUse: 'Requested Use',
        status: 'Status',
        season: 'Season {{number}}',
        inactive: 'INACTIVE',
      },
      noResults: {
        description: 'No results were found.',
        clear: 'Clear',
        yourSearch: 'your search',
        or: 'or',
        addProject: 'Add a Project',
      },
      details: {
        title: 'Details',
        options: {
          view: 'View',
          viewProduction: 'View Production',
          viewEditProjectDetails: 'Edit Details',
          share: 'Share',
          activateProduction: 'Activate Production',
          inactivateProduction: 'Make Production Inactive',
          activateEpisode: 'Activate Episode',
          inactivateEpisode: 'Make Episode Inactive',
          export: 'Export',
          archive: 'Archive',
          add: {
            features: 'Add Release',
            marketing: 'Add Campaign',
            television: 'Add Season',
            episode: 'Add Episode',
          },
        },
        viewTrack: 'View Track',
        track: {
          actualUse: 'Actual Use',
          approvedFees: 'Approved Fee',
          artists: 'Artists',
          assetDescription: 'Asset Description',
          assetTitle: 'Asset Title',
          estimatedFees: 'Estimated Fee',
          library: 'Library',
          master: 'Master Licensors',
          project: 'Project',
          requestedUse: 'Requested Use',
          sceneDescription: 'Scene Description',
          sceneTitle: 'Scene Title',
          sync: 'Sync Licensors',
          trackTitle: 'Track Title',
          writers: 'Writers',
        },
        licensor: {
          licensorName: 'Licensor Name',
          licensorType: 'Licensor Type',
          trackTitle: 'Track Title',
          writers: 'Writers',
          subLabel: 'Sub-Label',
          project: 'Project',
          assetTitle: 'Asset Title',
          assetDescription: 'Asset Description',
          sceneDescription: 'Scene Description',
          sceneTitle: 'Scene Title',
          requestedUse: 'Requested Use',
          actualUse: 'Actual Use',
          approvedFees: 'Approved Fee',
          estimatedFees: 'Estimated Fee',
        },
        feature: {
          cueSheetSegments: 'Cue Sheet Segments',
          intlReleaseDate: 'Intl. Release Date',
          project: 'Project',
          usReleaseDate: 'US Release Date',
        },
        marketing: {
          assetName: 'Asset Name',
          assetType: 'Asset Type',
          version: 'Version',
        },
        tv: {
          episode: 'Episode',
          add: 'Add',
          filter: 'Filter',
          search: 'Search episodes',
        },
      },
      searchResults: 'Search Results ({{count}})',
      projectsSearchResults: 'Displaying {{count}} Projects',
      tabs: {
        productions: 'Productions',
        tracks: 'Tracks',
        licensors: 'Licensors',
      },
    },
    requotePrompt: {
      content:
        'You have made a change to the rights for one or more songs that are currently not covered. You can REQUOTE the current clearance and the new rights will be added, or you can create a NEW CLEARANCE which allows you to separately clear for the new rights.',
      firstOption: 'NEW CLEARANCE',
      secondOption: 'REQUOTE',
      title: 'Track Usage Change',
    },
    containerPosition: {
      move: 'Move',
    },
    searchItem: {
      Campaign: 'Marketing Campaign',
      FilmRelease: 'Film Release',
      Episode: 'Episode',
      Track: 'Track',
    },
    licensing: {
      startUsing: 'Start Licensing by clicking on the',
      filterButton: '<0>filter</0><1/><2> button</2>',
      title: 'Licensing',
      filters: 'FILTERS',
      showInactived: 'Show Inactive Productions',
      showingCount: 'Showing {{firstItem}}-{{lastItem}} of {{total}}',
      groupByLicensors: 'Group by Licensors',
      cues: 'Cues',
      noResultsFilter: 'No results. Try different filters',
      noResults: 'No results.',
      filterTags: {
        tracks: 'Tracks',
        licensors: 'Licensors',
        productions: 'Productions',
        status: 'Status',
      },
      typeahead: {
        companyName: 'Company Name',
        email: 'Email',
        name: 'Contact Name',
      },
      clearanceTable: {
        production: 'Project / Production',
        trackTitleArtists: 'Track Title / Artist',
        licensor: 'Licensor',
        clearance: 'Clearance',
        approvedFee: 'Approved Fee',
        usage: 'Actual Usage',
        status: 'Status',
        rep: 'Rep',
        term: 'Term',
        territory: 'Territory',
        rights: 'Rights',
        details: 'Details',
      },
      licensingTable: {
        tableLabel: 'Licensing Table',
        trackTitle: 'Track Title',
        licensor: 'Licensor',
        clearance: 'Clearance',
        rights: 'Rights & Options',
        approvedFee: 'Total Approved Fee',
        usage: 'Actual Usage',
        status: 'Status',
        rep: 'Rep',
        expandRow: 'Expand row',
      },
      payeesTable: {
        name: 'Name',
        share: 'Share',
        po: 'PO #',
        invoice: 'Invoice #',
        checkAmount: 'Check Amount',
        check: 'Check #',
        datePaid: 'Date Paid',
        void: 'Void',
        add: 'Add',
        addPayee: 'Add Payee',
        addPayeeHintMessage: 'Add the companies or individuals that you required to pay for',
        totalAmount: 'Total Amount',
        totalAmountHintMessageMatch: 'Total Amount equals Total Approved Fees',
        totalAmountHintMessageMismatch: 'Total Amount is not equal to Total Approved Fees',
        letters: 'Letters',
        createLetter: 'Create Letter',
        fileUpload: 'File Upload',
        addDates: 'Add Dates',
      },
      payees: {
        editLicenseReq: 'Edit License Req',
        newLicense: 'New License',
        newCheckReq: 'New Check Req',
        letters: 'LETTERS',
        title: 'Payees',
        confirmCancel: 'Are you sure to cancel?',
        name: 'Name',
        sharePayments: 'Share Payments',
        purchaseOrder: 'PO Number',
        invoice: 'Invoice Number',
        checkAmount: 'Check Amount',
        checkNumber: 'Check Number',
        datePaid: 'Date Paid ',
        void: 'Void',
        check: 'CHECK',
        upload: 'UPLOAD',
        newPayee: 'NEW PAYEE',
        confirmDelete: 'Are you sure to delete the check?',
        licenseRequest: 'License Request',
        license: 'License',
        checkRequest: 'Check Request',
        total: 'Total',
        clickOn: 'Click ',
        toAddPayee: 'to add Payee',
        notifications: {
          update: 'Payee successfully updated',
          create: 'Payee successfully created',
        },
      },
      track: {
        clearance: 'Clearance',
        usage: 'Usage',
        status: 'Status',
      },
    },
    mainCueSheets: {
      title: 'Cue Sheets',
      loading: 'Loading Cue Sheets...',
      filters: {
        action: 'Action',
        showCompleted: 'Include Completed',
        showInactived: 'Show Inactive Productions',
        fields: {
          all: 'All',
          artist: 'Artist',
          cueSheetIds: 'Cue Sheet IDs',
          cueSheetSegments: 'Cue Sheet Segments',
          productionName: 'Production Name',
          productionNumber: 'Production Number',
          projectName: 'Project Name',
          segmentName: 'Segment Name',
          trackTitle: 'Track Title',
        },
      },
      tableHeader: {
        cueSheetUrgency: 'Urgent',
        status: 'Status',
        rapidCue: 'RapidCue',
        assignee: 'Assignee',
        cueSheet: 'Cue Sheet IDs',
        project: 'Project',
        production: 'Production',
        version: 'Version',
        airDate: 'Airdate',
        notes: 'Notes',
      },
      urgencyLevel: {
        Off: 'Not urgent',
        Medium: 'Medium',
        High: 'High',
        clear: 'Clear',
      },
      forms: {
        assignee: {
          header: 'Assign Rep',
          label: 'Name',
          disabledHint: 'Users can be disabled if they do not have access to one or more Cue Sheets',
        },
        notes: {
          header: 'Edit Notes',
          label: 'Notes',
        },
        status: {
          header: 'Change Status',
          label: 'Status',
        },
        filters: {
          header: 'Filters',
          urgency: {
            label: 'Urgent',
            Off: 'Not urgent',
            Medium: 'Medium',
            High: 'High',
          },
          division: 'Division',
          airStartDate: 'Air Start Date',
          airEndDate: 'Air End Date',
          status: 'Status',
          rapidCue: 'RapidCue',
          rapidCueStartDate: 'RapidCue Start Date',
          rapidCueEndDate: 'RapidCue End Date',
          assignee: 'Assignee',
          broadcaster: 'Broadcaster',
          productionCompany: 'Production Company',
          allOption: 'All',
          clearFilters: 'Clear Filters',
          productionType: 'Production Type',
          movie_cuesheet: 'Features',
          campaign_cuesheet: 'Marketing',
          series_cuesheet: 'Television',
          rapidCueStatus: {
            none: 'None',
            warning: 'Accepted (Warning)',
            missingInfo: 'Missing info',
            readytoSend: 'Ready to send',
          },
        },
        edit: 'Edit',
      },
      actionMenu: {
        assignee: 'Change Assignee',
        status: 'Update Status',
        rapidCue: 'Submit to RapidCue',
        rapidCueSubmitting: 'Submitting',
        export: 'Export',
        disabledItemMessage: 'You cannot select cue sheets from multiple divisions',
      },
      rapidCueModal: {
        title: 'RapidCue Validation',
        primaryHeading: 'Cue Sheet is missing data and cannot be submitted to RapidCue.',
        secondaryHeading: 'Correct the following errors Submit to RapidCue again.',
        goToCueSheet: 'Go to Cue Sheet',
        toastSuccess: '{{count}} Cue Sheet(s) submitted successfully.',
        toastError: '{{count}} Cue Sheet(s) failed validation.',
        validationSuccess: 'Cue Sheet can be submitted to RapidCue.',
        readytoSend: 'Ready to send',
      },
    },
    mainLicensors: {
      title: 'Licensors',
      subtitle: 'Showing {{pageInterval}} of {{totalCount}} licensors',
      loading: 'Loading licensors',
      tableHeader: {
        project: 'Project',
        production: 'Production',
        track: 'Track',
        licensorType: 'Licensor Type',
        licensorName: 'Licensor Name',
        share: 'Share',
        status: 'Status',
        notes: 'Notes',
        rights: 'Rights',
        approvedFees: 'Approved Fees',
        artist: 'Artist',
        division: 'Division',
        using: 'Using',
        options: 'Options',
        tags: 'Tags',
        sceneTitle: 'Scene Title',
        sceneDescription: 'Scene Description',
        requestedUseDuration: 'Requested Use & Duration',
        rep: 'Rep',
        quotedFees: 'Quoted Fees',
      },
      drawer: {
        letter: {
          disclosureButton: 'create letter',
          title: 'Create Schedule A Letter',
          licensorSelect: 'Licensor for Letter',
          typeSelect: 'Letter Type',
          submit: 'create letter',
          typeRequired: 'Please select a letter type',
        },
        uploadFile: {
          disclosureButton: 'upload file',
          title: 'Upload File',
          categorySelect: 'Category',
          status: 'Status',
          submit: 'save',
          filePicker: 'Attach a file',
          filePickerPlaceholder: 'Click to upload or drag and drop',
          filePickerPlaceholderSecondary: 'Max. 3MB',
          fileIsTooLarge: 'File too large',
          fileComplete: 'Complete',
          successMessage: 'File(s) uploaded successfully',
          noFileAttached: 'Please upload at least one file',
          categoryRequired: 'Please select a category',
        },
        updateStatus: {
          disclosureButton: 'update status',
          title: 'Update Status',
          status: 'Status',
          submit: 'save',
          successMessage: 'Status updated successfully',
          statusRequired: 'Please select a status',
        },
        tracks: {
          heading: 'Tracks',
          mixedTypesHint:
            'Your list contains both Master and Sync Licensors. Please edit the list to include either Master, Sync or One-Stop Licensors.',
          removeTooltip: 'Remove from list',
        },
        alerts: {
          letterEmptySelection: 'To create a letter, select one or more licensors',
          uploadEmptySelection: 'To upload a file, select one or more licensors',
          updateStatusEmptySelection: 'To update a status, select one or more licensors',
          multipleDivisions:
            'You have licensors selected that are in different divisions. Please update your selection and limit them to one division.',
        },
      },
      filters: {
        disclosureButton: 'Filter',
        disclosureButton_plural: 'Filters',
        addFilters: 'Add Filters',
        divisionPlaceholder: 'Select a Division',
        statusPlaceholder: 'Select a Status',
        showInactive: 'Show Inactive',
      },
      emptyState: {
        noResults: 'No results.',
        noResultsForSearch: {
          closingHint: '. Try adjusting your search.',
        },
        noResultsForFilters: {
          closingHint: '. Try adjusting your filters.',
        },
        noResultsForSearchAndFilters: {
          preSearch: 'No results for',
          postSearch: 'and',
          postFilters: 'filters',
          closingHint: '. Try adjusting your filters and/or search.',
        },
      },
    },
    trackDetails: {
      disableLicensorTooltip: 'Click to disable from clearance process',
      asset: 'Asset',
      scene: 'Scene',
      actual: 'Actual',
      requested: 'Requested',
      licensors: 'Licensors',
      trackNotes: 'Track Notes',
      trackUsage: 'Usage',
      rightsAndOptions: 'Rights and Options',
      total: 'Total',
      totals: 'Totals',
      totalShare: 'Total Share',
      master: 'Master',
      sync: 'Sync',
      oneStop: 'One Stop',
      addNotes: 'Add Notes',
      setDates: 'Set Dates',
      onLetterAs: 'On Letter As',
      addUsage: 'Add Usage',
      addOption: 'Add Options',
      addRights: 'Add Rights',
      addLicensors: 'Add Licensors',
      editLicensor: 'Edit Licensor',
      addUnion: 'Add Union',
      markNonUnion: 'Mark as Non-Union',
      addMaster: 'Add Master',
      addSync: 'Add Sync',
      addOther: 'Add Other',
      addOneStop: 'Add One Stop',
      noLicensors: 'No Licensors',
      noLicensorsDescription: 'Licensors will be listed here',
      noTotals: 'No Totals',
      noTotalsDescription: 'Total fees and shares will be listed here',
      noRightsAndOptions: 'No Rights/Options',
      noRightsAndOptionsDescription: 'Rights and options will be listed here',
      noUsage: 'No Usage',
      noUsageDescription: 'Active usage will be listed here',
      addClearance: 'Add Clearance',
      rename: 'Rename',
      delete: 'Delete',
      showInactive: 'Show Inactive',
      makeActive: 'Make Active',
      makeInactive: 'Make Inactive',
    },
    tracksView: {
      title: 'Tracks',
      subtitle: 'Showing {{pageInterval}} of {{totalCount}} tracks',
      loading: 'Loading tracks',
      tableHeader: {
        division: 'Division',
        project: 'Project',
        production: 'Production',
        sceneTitle: 'Scene',
        sceneDescription: 'Scene Description',
        clearance: 'Clearance',
        track: 'Track',
        artist: 'Artist',
        status: 'Status',
        masterLicensorNames: 'Master Licensors',
        labels: 'Labels',
        masterLicensorFees: 'Master Fees',
        syncLicensorNames: 'Sync Licensors',
        syncLicensorFees: 'Sync Fees',
        publishers: 'Publishers',
        writers: 'Writers',
        using: 'Using',
        tags: 'Tags',
        notes: 'Notes',
        term: 'Term',
        territories: 'Territories',
        rights: 'Rights',
        options: 'Options',
      },
      filters: {
        disclosureButton: 'Filter',
        disclosureButton_plural: 'Filters',
        addFilters: 'Add Filters',
        divisionPlaceholder: 'Select a Division',
        statusPlaceholder: 'Select a Status',
        showInactive: 'Show Inactive',
      },
      addTrackButton: 'Add a Track',
    },
    moveTrackToProduction: {
      button: 'Move Track',
      trackMoved: 'Moved to {{sceneDescription}}',
      selectAScene: 'Please, select a scene.',
      visit: 'Visit',
      warningAboutMove: 'If this track is included multiple times in your production, all of the tracks will be moved.',
      searchInputPlaceholder: 'Move track to...',
    },
    qwireDataGridUIConfigManager: {
      renameView: 'Rename view',
      deleteView: 'Delete view',
      addView: 'Add View',
      deleteConfirmation: 'Are you sure you want to delete this view?',
    },
    unions: {
      details: {
        status: 'Status',
        useType: 'Union Type',
        fee: 'Fee',
        editButton: 'Edit',
        removeButton: 'Remove',
        unionRemoved: 'Union/Non-Union removed',
        removeUnionPromptMessage: 'You are about to remove this Union. Are you sure you want to continue?',
        removeNonUnionPromptMessage: 'You are about to remove Non-Union. Are you sure you want to continue?',
      },
    },
    contactSearch: {
      placeholder: 'Search by company or contact',
    },
  },
};

export default en;
