import { datadogRum } from '@datadog/browser-rum';

// process.env.REACT_NODE_ENV !== 'staging' &&
//     process.env.REACT_NODE_ENV !== 'production' ? false :

datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APP_ID,
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: process.env.REACT_APP_DD_SERVICE_NAME,
    env: process.env.REACT_APP_NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [(url) => url.includes('/api')],
});
