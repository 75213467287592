/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';
import lensPath from 'ramda/src/lensPath';
import set from 'ramda/src/set';
import always from 'ramda/src/always';
import compose from 'ramda/src/compose';
import omit from 'ramda/src/omit';
import prop from 'ramda/src/prop';
import { clearData } from 'redux-core/auth/actions';
import { tracksNotExpanded } from 'components/ProductionOverview/Overview/components/constants';
import * as actions from './actions';

const defaultState = {
  expandedTrack: tracksNotExpanded,
  project: {},
  filters: {},
  selected: {},
  useInfo: {},
  footerInfo: {},
};

const reducer = {
  [actions.cleanOverview]: () => defaultState,
  [actions.cleanExpandedTrack]: (state) => set(lensPath(['expandedTrack']), tracksNotExpanded)(state),
  [actions.setExpandedTrack]: (state, { payload }) => set(lensPath(['expandedTrack']), payload)(state),
  [actions.setProductionBudget]: (state, { payload }) => set(lensPath(['selected', 'budget']), payload)(state),
  [actions.setProductionVersion]: (state, { payload }) =>
    set(lensPath(['selected', 'budget', 'versions']), payload)(state),
  [actions.setProject]: (state, { payload }) => set(lensPath(['project']), payload)(state),
  [actions.setSelected]: (state, { payload }) => set(lensPath(['selected']), payload)(state),
  [actions.removeMetadata]: (state, { payload }) => {
    const newSelected = compose(omit(payload), prop('selected'))(state);
    return set(lensPath(['selected']), newSelected)(state);
  },
  [actions.setUseInfo]: (state, { payload }) => set(lensPath(['useInfo']), payload)(state),
  [actions.setFooterInfo]: (state, { payload }) => set(lensPath(['footerInfo']), payload)(state),
  [clearData]: always(defaultState),
};

export default handleActions(reducer, defaultState);
