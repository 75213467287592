/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { Delete as DeleteIcon } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import assoc from 'ramda/src/assoc';
import map from 'ramda/src/map';
import pick from 'ramda/src/pick';
import prop from 'ramda/src/prop';
import propOr from 'ramda/src/propOr';
import reduceBy from 'ramda/src/reduceBy';
import { useFormikContext } from 'formik';
import { Edit } from 'components/styleguide';
import { deleteRightsOwners, updateRightsOwners } from 'redux-core/rights-owners/services';
import {
  FormButton,
  FormInputFormat,
  FormInputText,
  QTracksTypeAheadManageLicensors,
} from 'components/common/Form/Fields';
import { FormControlLabelWrapper } from 'components/common/Form/Fields/FormField/FormField';
import { ContactSearch } from 'components/common/ContactSearch/ContactSearch';
import { getQwireTracksLicensorsCall } from 'redux-core/tracks/services';
import { LICENSOR_TYPES } from 'utils/constants';
import { useRootTranslation, usePrompt, useSnackbar } from 'utils/hooks';
import { getLicensorContacts, getContactByIdCall } from 'redux-core/contacts/services';
import { requestWithError } from 'utils/request';
import enhancer from './enhancer';
import ContactForm, { ADDRESS_FIELDS } from '../ContactForm';

const getTypes = (type) => {
  if (type === LICENSOR_TYPES.ONE_STOP) {
    return [LICENSOR_TYPES.MASTER, LICENSOR_TYPES.SYNC, LICENSOR_TYPES.OTHER];
  }
  return [type];
};

const removeDuplicatesFn = (list) => {
  const idsByName = reduceBy(
    (acc, { qwireTracksRightsOwnerId, hidden }) => acc.concat({ qwireTracksRightsOwnerId, hidden }),
    [],
    prop('name'),
    list.filter(({ name }) => !!name),
  );

  return Object.entries(idsByName).map(([name, values]) => ({
    name,
    qwireTracksRightsOwnerId: values[0].qwireTracksRightsOwnerId,
    hidden: values[0].hidden,
  }));
};

const isValidEmail = (value) => Yup.string().email().required().isValidSync(value);

const getContactNameFromValue = (value) => {
  const name = value.split(' ');
  const firstName = name[0];
  const lastName = name.slice(1).join(' ');
  return {
    firstName,
    lastName,
  };
};

const BasicInfoForm = ({
  clearancesFetch,
  divisionId,
  isEditingLicensor,
  onClose,
  refetchLicensor,
  rootKey,
  contactKey,
  type,
  licensorContacts,
  licensorContact,
  isVerifiedTrack,
  readOnly,
  ...props
}) => {
  const openSnackbar = useSnackbar();
  const openPrompt = usePrompt();
  const {
    setFieldValue,
    values: { licensor = {} },
    errors,
  } = useFormikContext();
  const repeatedLicensorError = prop('duplicatedLicensor', errors);
  const qTrack = propOr({}, 'qTrack')(licensor);
  const [disabled, setDisabled] = useState(!qTrack.name);
  const [isUpdatingLicensor, setIsUpdatingLicensor] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const rootT = 'drawers.licensorsAddEdit.basicInfo';
  const contactT = 'drawers.licensorsAddEdit.contact';
  const t = useRootTranslation(rootT);
  const t2 = useRootTranslation(contactT);
  const globalT = useRootTranslation('global');
  useEffect(() => setDisabled(!qTrack.name), [qTrack]);
  const [contacts, setContacts] = useState(licensorContacts);
  const [contact, setContact] = useState(licensorContact);

  const handleUpdateROName = async () => {
    setIsUpdatingLicensor(true);

    await updateRightsOwners({
      divisionId,
      id: licensor.id,
      name: licensor.newName,
    });

    setIsUpdatingLicensor(false);
    refetchLicensor();
  };

  const handleSelectLicensor = async ({ divisionId, selected, setFieldValue }) => {
    if (!selected?.qwireTracksRightsOwnerId) return;
    const { qwireTracksRightsOwnerId } = selected;
    const contacts = await getLicensorContacts({
      divisionId,
      qwireTracksRightsOwnerId,
    });

    setContacts(contacts);

    const lastUsedContact = contacts.find(prop('usedLast'));
    setContact({
      id: lastUsedContact?.id,
      name: `${lastUsedContact?.firstName} ${lastUsedContact?.lastName}`,
    });
    if (!lastUsedContact) return;
    const contact = await getContactByIdCall({
      divisionId,
      id: lastUsedContact.id,
    });
    setContact({
      id: contact.id,
      name: `${contact.firstName} ${contact.lastName}`,
    });
    setFieldValue('licensor.contact', pick(['id', 'firstName', 'lastName', 'email', ...ADDRESS_FIELDS], contact));
    // State needs to be updated in a separate statement which has to come
    // after updating Country, otherwise the state field will be reset
    // due to detecting that the Country changed
    setFieldValue('licensor.contact.state', contact.state);
  };

  const handleContactChange = async (value) => {
    if (value) {
      if (typeof value === 'string') {
        setContact({ id: undefined, name: value });

        setFieldValue(`${contactKey}.id`, undefined);
        setFieldValue(`${contactKey}.isCreating`, true);

        if (isValidEmail(value)) {
          setFieldValue(`${contactKey}.firstName`, '');
          setFieldValue(`${contactKey}.lastName`, '');
          setFieldValue(`${contactKey}.email`, value);
        } else {
          const { firstName, lastName } = getContactNameFromValue(value);
          setFieldValue(`${contactKey}.firstName`, firstName);
          setFieldValue(`${contactKey}.lastName`, lastName);
          setFieldValue(`${contactKey}.email`, '');
        }
      } else {
        setContact({ id: value.id, name: value.name });

        const { firstName, lastName } = getContactNameFromValue(value.name);
        setFieldValue(`${contactKey}.id`, value.id);
        setFieldValue(`${contactKey}.firstName`, firstName);
        setFieldValue(`${contactKey}.lastName`, lastName);
        setFieldValue(`${contactKey}.email`, value.email);
        setFieldValue(`${contactKey}.isCreating`, undefined);
        const retrievedContact = await getContactByIdCall({
          divisionId,
          id: value.id,
        });
        ADDRESS_FIELDS.forEach((field) => setFieldValue(`${contactKey}.${field}`, retrievedContact[field]));
      }
    }
  };

  return (
    <Grid item container spacing={2}>
      <Grid item xs={6} alignItems="center">
        {isEditingName ? (
          <FormInputText
            name={`${rootKey}.newName`}
            label={`${rootT}.${type}.name`}
            testId="licensor-name-edited"
            error={repeatedLicensorError}
            disabled={isVerifiedTrack}
          />
        ) : (
          <QTracksTypeAheadManageLicensors
            alwaysShowCreateButton
            call={async (term) =>
              map(
                (x) => assoc('qwireTracksRightsOwnerId', prop('id', x), x),
                await getQwireTracksLicensorsCall({
                  term,
                  types: getTypes(type),
                }),
              )
            }
            removeDuplicatesFn={removeDuplicatesFn}
            idKey="name"
            onSelect={(selected) =>
              requestWithError(handleSelectLicensor, {
                divisionId,
                selected,
                setFieldValue,
              })
            }
            label={`${rootT}.${type}.name`}
            name={`${rootKey}.qTrack`}
            required
            error={repeatedLicensorError}
            disabled={isVerifiedTrack}
            divisionId={divisionId}
          />
        )}
      </Grid>
      {isEditingLicensor && (
        <Grid container item xs={2} alignItems="center">
          <Grid item>
            {isEditingName ? (
              <Box mt={repeatedLicensorError ? 0 : 2.5}>
                <IconButton
                  size="small"
                  disabled={isUpdatingLicensor}
                  onClick={() =>
                    openPrompt({
                      content: t('updateLicensorNamePrompt'),
                      onConfirm: async () => {
                        try {
                          handleUpdateROName();
                        } catch {
                          openSnackbar({ message: globalT('genericError') });
                        }
                      },
                    })
                  }
                >
                  <CheckIcon color="primary" />
                </IconButton>
                <IconButton size="small" disabled={isUpdatingLicensor} onClick={() => setIsEditingName(false)}>
                  <ClearIcon color="error" />
                </IconButton>
              </Box>
            ) : (
              !isVerifiedTrack &&
              !readOnly && (
                <Box mt={repeatedLicensorError ? 0 : 2.5}>
                  <Edit
                    size={20}
                    onClick={() => {
                      setFieldValue(`${rootKey}.newName`, licensor.name);
                      setIsEditingName(true);
                    }}
                  />
                </Box>
              )
            )}
          </Grid>
        </Grid>
      )}
      <Grid item xs={2}>
        <FormInputFormat
          testId="basic-info-share"
          name={`${rootKey}.share`}
          label={`${rootT}.share`}
          type="percentage"
          decimalScale={5}
          disabled={disabled || isVerifiedTrack}
          textAlign="left"
          max={100}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabelWrapper label={t2('contact')}>
          <ContactSearch
            divisionId={divisionId}
            value={contact}
            onChange={handleContactChange}
            onCreate={handleContactChange}
          />
        </FormControlLabelWrapper>
      </Grid>
      <ContactForm
        contact={contact}
        rootT={rootT}
        disabled={disabled}
        divisionId={divisionId}
        {...props}
        rootKey={rootKey}
        contactKey={contactKey}
        readOnly={readOnly}
        licensorContacts={contacts}
      />
      {licensor.id && !isVerifiedTrack && (
        <Grid container justify="flex-end" alignItems="center">
          <FormButton
            alwaysEnabled
            children={t('deleteLicensor')}
            size="md"
            startIcon={<DeleteIcon />}
            variant="destructive"
            onClick={() =>
              openPrompt({
                content: t('deleteLicensorPrompt'),
                onConfirm: async () => {
                  try {
                    await deleteRightsOwners({ id: licensor.id });
                    openSnackbar({ message: t('deletedLicensor') });
                    await clearancesFetch();
                    onClose();
                  } catch {
                    openSnackbar({ message: globalT('genericError') });
                  }
                },
              })
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

BasicInfoForm.propTypes = {
  clearancesFetch: PropTypes.func,
  divisionId: PropTypes.number.isRequired,
  isEditingLicensor: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  refetchLicensor: PropTypes.func,
  rootKey: PropTypes.string,
  type: PropTypes.string,
  isVerifiedTrack: PropTypes.bool,
  licensorContacts: PropTypes.object,
  readOnly: PropTypes.bool,
};
export default enhancer(BasicInfoForm);
