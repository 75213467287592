import { createSelector } from 'reselect';
import { getLocationParametersSelector } from 'redux-core/router/selectors';
import equals from 'ramda/src/equals';
import keys from 'ramda/src/keys';
import length from 'ramda/src/length';
import path from 'ramda/src/path';
import pathOr from 'ramda/src/pathOr';
import prop from 'ramda/src/prop';
import pick from 'ramda/src/pick';
import { get } from 'utils/object';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';

const getOverviewRoot = prop('overview');

export const getProjectSelector = createSelector(getOverviewRoot, prop('project'));

export const getProjectIdSelector = createSelector(getProjectSelector, prop('id'));
export const getSelectedSelector = createSelector(getOverviewRoot, prop('selected'));

/**
 * Return id and title from overview.selected
 * @return {object} { id: number, title: string }
*/
export const getSelectedIdAndTitleSelector = createSelector(getSelectedSelector, pick(['id', 'title']));

export const getSelectedProjectIdSelector = createSelector(getLocationParametersSelector, prop('projectId'));

export const getSelectedProductionIdSelector = createSelector(getLocationParametersSelector, prop('id'));

export const getSelectedProductionObjectIdSelector = createSelector(getSelectedSelector, prop('objectId'));

export const getSelectedProductionDivisionIdSelector = createSelector(
  getLocationParametersSelector,
  prop('divisionId'),
);

export const getSelectedProductionTypeSelector = createSelector(getLocationParametersSelector, prop('type'));

export const getUseInfoSelector = createSelector(getOverviewRoot, prop('useInfo'));

export const getFooterInfoSelector = createSelector(getOverviewRoot, prop('footerInfo'));

export const getHasTracksSelector = createSelector(getUseInfoSelector, keys, length, Boolean);

export const getSelectedBudgetIdSelector = createSelector(getSelectedSelector, path(['budget', 'id']));

export const getSelectedBudgetVersionsSelector = createSelector(getSelectedSelector, path(['budget', 'versions']));

export const getBudgetSelector = createSelector(getSelectedSelector, prop('budget'));

export const getIsNewProductionSelector = createSelector(
  getSelectedSelector,
  getLocationParametersSelector,
  (selected, params) => !get('id')(selected) && !get('id')(params),
);

export const getSelectedProductionCompaniesSelector = createSelector(
  getSelectedSelector,
  pathOr([], ['additionalData', 'productionEntities']),
);

export const getExpandedTrackSelector = createSelector(getOverviewRoot, prop('expandedTrack'));

export const getIsTrackExpandedSelector = (cueContainerId, trackId) =>
  createSelector(getExpandedTrackSelector, (expandedTrack) => {
    if (cueContainerId) {
      return equals(expandedTrack, [cueContainerId, trackId]);
    }
    return trackId === expandedTrack[1];
  });

export const getSelectedCueSheetHeadBoardSelector = createSelector(getSelectedSelector, prop('cueSheetHeadboard'));

export const getProjectTenantIdSelector = createSelector(getProjectSelector, prop('tenantId'));

export const getSelectedCueSheetHeadBoardIdSelector = createSelector(getSelectedCueSheetHeadBoardSelector, prop('id'));

export const getSelectedCueSheeVersionSelector = createSelector(getSelectedSelector, prop('currentCueSheetVersion'));

export const getCurrentCueSheeVersionIdSelector = createSelector(getSelectedCueSheeVersionSelector, prop('id'));

export const getSelectedSeasonId = createSelector(
  getSelectedProductionTypeSelector,
  getSelectedSelector,
  (type, selected) => (type === NORMALIZED_PRODUCTION_TYPES.TV ? path(['path', 1, 'id'], selected) : undefined),
);

export const getSelectedSeasonReleaseId = createSelector(
  getSelectedProductionTypeSelector,
  getSelectedSelector,
  (type, selected) => (type === NORMALIZED_PRODUCTION_TYPES.TV ? path(['path', 2, 'id'], selected) : undefined),
);
